import top from "../../components/chart/top.vue";
import titleCom from "../../components/chart/title.vue";
import deviceTitle from "../../components/chart/device-title.vue";
import longTitleCom from "../../components/chart/long-title.vue";
import pieChart from "../../components/echart/pie-task.vue";
import lineChart from "../../components/echart/line.vue";
import * as echarts from "echarts";
import footerCom from "../../components/chart/footer.vue";
import logout from "../../components/chart/logout.vue";
import { inspectionAnalyse, shopTopRed } from "@/api/digital-inspection";
import {
  shopLi,
  morningCheckTable,
  hygiene,
  shopGl,
  morningCheckInfo,
  lyRate,
  lyPic,
  lyRecordList,
  shopStaffHealth,
  dayInfo,
  staffControl,
  workEnv as workEnvDay,
  workPro,
  materialSafe,
  workSupervise as workSuperviseDay,
  material as materialDay,
  licenseAlarmList,
  sceneAlarmList,
  faceAlarmList,
  sampleAlarmList,
  complaintAlarmList,
  rectifyAlarmList,
  supplierAlarmList,
  supplierAdventAlarmList,
  healthUpdateAlarmList,
  healthAlarmList,
  strangerAlarmList,
  accessAlarmList,
  aiAlarmList,
  temperatureAlarmList,
  selfAlarmList,
  leaveAlarmList,
  FdData,
  mealShow,
  supplier,
} from "@/api/shop";
import {
  closeAlarmAll,
  foodSafetyIndex,
  shopScoreList,
  shopStatistics,
  realAlarm,
  notice,
  dayRecord,
  dayLeftList,
  dayRightList,
  monthRecordSummary as monthRecord,
  weekRecord,
  weekDetail,
  weekRectify,
  conversionDate,
  realMonit,
  thirtyAlarmList,
} from "@/api/master.js";
import {
  znDevice,
  ygLeace,
  sample,
  warehouse,
  clothing,
  kitchen,
  disinfect,
  pesticide,
  deviceBf,
  deviceJg,
  yjCenter,
  warehouseFu,
  shopScoreDetail,
  shopScoreDetailPage,
  dressingPop,
  ratPop,
  spacePop,
  healthPop,
  nongPop,
  lymealLi,
  lyPicLi,
} from "../../api/device";
import scrollLine from "../../components/scorll-transverse/index.vue";
import VueQr from "vue-qr";
import scroolDiv from "@/components/scroll-view/index.vue";
let hlsPlayer = null;
let hlsPlayer2 = null;
let hlsPlayer3 = null;
let hlsPlayer4 = null;
let hlsPlayerReal = null;
let hlsPlayerReal2 = null;
export default {
  components: {
    top,
    titleCom,
    footerCom,
    pieChart,
    lineChart,
    longTitleCom,
    logout,
    VueQr,
    scroolDiv,
    scrollLine,
    deviceTitle,
  },
  data() {
    return {
      valueWeek: "",
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          const nextWeekStart = new Date();
          const twelveWeeksAgo = new Date();

          // 设置下周开始时间
          nextWeekStart.setDate(today.getDate() + (6 - today.getDay()));
          // 设置12周前的时间
          twelveWeeksAgo.setDate(today.getDate() - today.getDay() - 1 - 11 * 7);
          // 禁用下周及以后的日期
          // 禁用12周之前的日期
          return (
            time.getTime() > nextWeekStart.getTime() ||
            time.getTime() < twelveWeeksAgo.getTime()
          );
        },
      },
      cardNum: 0,
      digitToChinese: {
        0: "零",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
        7: "七",
        8: "八",
        9: "九",
        10: "十",
      },
      specialDigits: {
        10: "十",
        100: "百",
        1000: "千",
      },
      nowWeekBegin: "",
      nowWeekEnd: "",
      redScore: 100,
      // 红榜数据
      redData: [
        // {
        //   shopName: "行园餐厅",
        //   score: 0,
        // },
        // {
        //   shopName: "行园餐厅11",
        //   score: 0,
        // },
        // {
        //   shopName: "行园餐厅2",
        //   score: 0,
        // },
      ],
      sceneList: [],
      widthPix: 1,
      imgUrl: "https://dobaycdn.anasit.com",
      originUrl: window.location.origin,
      currentFile: 0,
      leftPx: 0,
      current: 0,
      widths: [],
      shopId: 11620,
      nextShopId: 101,
      tabLeft: 0,
      nextTabLeft: 0,
      scrollIng: null,
      warnShow: false,
      shopList: [
        {
          shopId: 11620,
          shopName: "合川一食堂",
        },
        {
          shopId: 11621,
          shopName: "合川二食堂",
        },
        {
          shopId: 11622,
          shopName: "合川三食堂",
        },
        {
          shopId: 14812,
          shopName: "合川四食堂",
        },
        // {
        //   shopId: 11623,
        //   shopName: "华岩三食堂",
        // },
        {
          shopId: 11624,
          shopName: "华岩一食堂",
        },
        {
          shopId: 11702,
          shopName: "石桥铺食堂",
        },
      ],
      num: "scrollLineKey",
      pointList: [
        {
          shopId: 11620,
          shopName: "合川一食堂",
          again: false,
          isAlarm: false,
          leftpx: "1.1042rem", //  .4792
          alarmLeft: "1.1667rem",
          alarmBottom: "2.5rem",
          bottompx: "2.5rem", // .276
        },
        {
          shopId: 11621,
          shopName: "合川二食堂",
          again: false,
          isAlarm: false,
          leftpx: "1.0313rem", //  .4792
          alarmLeft: "1.1038rem",
          alarmBottom: "2.6042rem",
          bottompx: "2.6042rem", // .276
        },
        {
          shopId: 11622,
          shopName: "合川三食堂",
          again: false,
          isAlarm: false,
          leftpx: "0.9792rem", //  .4792
          alarmLeft: "1.0438rem",
          alarmBottom: "2.7083rem",
          bottompx: "2.7083rem", // .276
        },
        {
          shopId: 14812,
          shopName: "合川四食堂",
          again: false,
          isAlarm: false,
          leftpx: "1.59rem", //  .4792
          alarmLeft: "0.9998rem",
          alarmBottom: "2.7083rem",
          bottompx: "1.99993rem", // .276
        },
        // {
        //   shopId: 11623,
        //   shopName: "华岩三食堂",
        //   again: false,
        //   isAlarm: false,
        //   leftpx: "2.8854rem",
        //   alarmLeft: "2.9508rem",
        //   bottompx: "1.2925rem",
        //   alarmBottom: "1.2925rem",
        // },
        {
          shopId: 11624,
          shopName: "华岩一食堂",
          again: false,
          isAlarm: false,
          leftpx: "2.9046rem",
          alarmLeft: "2.9908rem",
          bottompx: "1.2025rem",
          alarmBottom: "1.2025rem",
        },
        {
          shopId: 11702,
          shopName: "石桥铺食堂",
          again: false,
          isAlarm: false,
          leftpx: "3.1146rem",
          alarmLeft: "3.1817rem",
          bottompx: "1.893rem",
          alarmBottom: "1.893rem",
        },
      ],
      wisdomData: {
        onlineRate: 0,
        unlineRate: 0,
        lightMonitorDevices: 0,
        fireDevices: 0,
        aiDevices: 0,
        checkMorning: 0,
        outInDevices: 0,
        iceBoxDevices: 0,
        nongyaoDevices: 0,
        depositDevices: 0,
        pantryDevices: 0,
      },
      wisdomMonitData: [],
      foodSafetyIndexData: [],
      shopStatisticsData: {
        shopNum: 0,
        userCnt: 0,
        time: 0,
        sceneNum: 0,
        deviceNum: 0,
        channelCnt: 0,
        typeCnt: 0,
      },
      infoPublicData: {
        licenceStatus: "--",
        infoPublicData: "--",
        infoPublicData: "--",
      },
      staffManageData: {
        status: "--",
        staffCnt: "--",
        alarmStatus: "--",
      },
      workEnvData: {
        status: "--",
        picture: "",
      },
      materialData: {
        qualifiedCnt: 0,
        alarmCnt: 0,
        nongyaoStatus: "",
      },
      workProcessData: {
        status: "--",
        picture: "",
      },
      maintenanceData: {
        deviceCnt: 0,
        channelCnt: 0,
      },
      alarmNotice: undefined,
      alarmVisible: false,
      thirdSecondAlarmData: [],
      alarmNoticeList: [],
      timeer: null,
      // 报警弹窗
      noticeTimer: null,
      shopIndexData: [
        {
          shopName: "合川一食堂",
        },
        {
          shopName: "合川二食堂",
        },
        {
          shopName: "合川三食堂",
        },
        // {
        //   shopName: "华岩三食堂",
        // },
        {
          shopName: "华岩一食堂",
        },
        {
          shopName: "石桥铺食堂",
        },
        {
          shopName: "合川四食堂",
        },
      ],

      //食安指数timer，用于重新请求接口，销毁timer
      shopIndexTimer: null,
      // 数字巡检timer
      digitalTimer: null,
      // 智慧设备分析
      wisdomDeviceTimer: null,
      showFilpper: false,
      flipperTimer: null,
      sopTimer: null,
      rotate: 0,
      opacity: 1,
      rotate2: 0,
      opacity2: 1,
      rotate3: 0,
      opacity3: 1,
      showPoint: true,
      warnNoticeTimer: null,
      // 日管控
      dayList: [
        {
          shopName: "合川一食堂",
          shopId: 11620,
          status: 0,
          pass: 4,
          unqualify: 4,
          total: 26,
        },
      ],
      dayDialogShow: false,
      dayTitle: "",
      redSingle: [1, 2, 3, 4, 5, 7, 8, 11, 12, 14, 16, 19, 21, 21, 23, 24],
      dayShopId: "",
      dayRecordId: "",
      dayRecordData: [],
      dayLeftData: [],
      dayRightData: [],
      dayRightLoading: false,
      dayLeftLoading: false,
      // 周排查
      weekDialogShow: false,
      weekTitle: "",
      weekTreeData: [
        {
          id: 703,
          label: "重庆工商职业学院",
          type: 1,
          children: [
            {
              id: 710,
              label: "合川校区",
              type: 2,
              children: [
                {
                  id: 11620,
                  label: "合川一食堂",
                  type: 3,
                },
                {
                  id: 11621,
                  label: "合川二食堂",
                  type: 3,
                },
                {
                  id: 11622,
                  label: "合川三食堂",
                  type: 3,
                },
                {
                  id: 14812,
                  label: "合川四食堂",
                  type: 3,
                },
              ],
            },
            {
              id: 711,
              label: "华岩校区",
              type: 2,
              children: [
                // {
                //   id: 11623,
                //   label: '华岩三食堂',
                //   type:3,
                // }
                // ,
                {
                  id: 11624,
                  label: "华岩一食堂",
                  type: 3,
                },
              ],
            },
            {
              id: 712,
              label: "石桥铺校区",
              type: 2,
              children: [
                {
                  id: 11702,
                  label: "石桥铺食堂",
                  type: 3,
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      weekDate: {
        beginTime: "",
        endTime: "",
      },
      date: "",
      showWeekData: {},
      weekData: [],
      weekDetailData: [],
      weekProblemData: [],
      weekRectifyData: [],
      weekTab: 1,
      weekParams: {
        recordId: "",
        shopId: "",
        structureId: "",
      },
      evaluateLabel: [
        "食品安全风险可控，无较大食品安全风险隐患。",
        "存在食品安全风险隐患，需尽快采取防范措施。",
        "存在严重食品安全风险隐患，需尽快采取防范措施，请公司负责人重视。",
      ],
      // 月调度
      monthDialogShow: false,
      monthData: [],
      monthDetailData: {
        hiddenItems: "",
      },
      showCurrent: 1,
      operateHeight: [],
      // 智慧设备概览
      deviceDialogShow: false,
      deviceDialogParam: {
        shopId: "",
      },
      deviceMonitData: {},
      wisdomDataDialog: {},
      safeList: [],
      deviceDialogShopData: [],
      deviceDialogTimer: null,
      mainSwiperList: [],
      // 食堂概览
      shopScoreParams: {
        shopId: 0,
        grade: 1,
        current: 1,
        size: 10,
      },
      safeScoreShopList: [
        {
          shopId: 11620,
          shopName: "合川一食堂",
          isChoose: false,
        },
        {
          shopId: 11621,
          shopName: "合川二食堂",
          isChoose: false,
        },
        {
          shopId: 11622,
          shopName: "合川三食堂",
          isChoose: false,
        },
        {
          shopId: 14812,
          shopName: "合川四食堂",
          isChoose: false,
        },
        // {
        //   shopId: 11623,
        //   shopName: "华岩三食堂",
        //   isChoose:false,
        // },
        {
          shopId: 11624,
          shopName: "华岩一食堂",
          isChoose: false,
        },
        {
          shopId: 11702,
          shopName: "石桥铺食堂",
          isChoose: false,
        },
      ],
      scoreChooseList: [
        {
          label: "严重风险",
          isChoose: true,
          score: 13,
        },
        {
          label: "食堂预警",

          isChoose: false,
          score: 13,
        },
        {
          label: "注意预防",

          isChoose: false,
          score: 0,
        },
      ],
      shopDialogShow: false,
      shopDialogCurrent: 1,
      morningInfo: {},
      shopStaffInfo: {},
      morningTable: [],
      shopDialogParams: {
        shopId: "",
      },
      // 食堂专管
      liuyangShow: false,
      shopRIskHeadList: [
        {
          label: "严重风险 ",
          type: 1,
          score: 13,
          isChoose: true,
        },
        {
          label: "食堂预警 ",
          type: 2,

          score: 12,
          isChoose: false,
        },
        {
          label: "注意预防 ",
          type: 3,

          score: 10,
          isChoose: false,
        },
      ],
      shopTopLoading: false,
      safeScoreList: [],
      lyDetailList: [],
      tableLoading: false,
      total: 0,
      lyDetailPicList: [],
      shopPicLoading: false,
      imagReviewList: [],
      mainSwiperListDialog: [],
      recordList: [],
      lyPicList: [],
      sampleDialogData: {
        show: false,
        index: 0,
        data: {},
      },
      showScroll: true,
      lastWord: "",
      noticeTitle: "",
      noticeDialog: false,
      noticeList: [],
      popParams: {
        current: 1,
        size: 10,
      },
      titleContent: "",
      popWord: "",
      splitWord: "",
      splitWord2: "",
      splitWord3: "",
      dayRate: {},
      dayCards: [
        {
          label1: "食品经营许可证",
          value1: "",
          label2: "明厨亮灶",
          value2: "",
          label3: "菜单公示",
          value3: "",
        },
        {
          label1: "健康证",
          value1: "",
          label2: "晨检",
          value2: "",
          label3: "着装行为",
          value3: "",
        },
        { label1: "环境消毒", value1: "", label2: "场所清洁", value2: "" },
        { label1: "食品留样", value1: "", label2: "留样图片", value2: "" },
        {
          scroll: true,
          data: [
            {
              label1: "供应商证照公示",
              value1: "",
              label2: "仓库挡鼠防潮",
              value2: "",
              label3: "食材农残检测",
              value3: "",
            },
            {
              label1: "食堂原料验收状态",
              value1: "",
              label2: "索证索票",
              value2: "",
            },
          ],
        },

        { label1: "餐饮具消毒", value1: "", label2: "消毒结果", value2: "" },
      ],
      realMonitData: [
        {
          channelId: "287ccd0f22a74e4ca12c6b401f60d240",
          channelName: "合川-一食堂-1号门大厅",
          dashboardSetId: 3,
          isDisplay: 1,
          sceneName: "大厅",
          shopId: 11620,
          shopName: "合川一食堂",
          sort: 1,
          videoUrl: "",
        },
        {
          channelId: "287ccd0f22a74e4ca12c6b401f60d240",
          channelName: "合川-一食堂-1号门大厅",
          dashboardSetId: 3,
          isDisplay: 1,
          sceneName: "大厅",
          shopId: 11620,
          shopName: "合川一食堂",
          sort: 2,
          videoUrl: "",
        },
        {
          channelId: "287ccd0f22a74e4ca12c6b401f60d240",
          channelName: "合川-一食堂-1号门大厅",
          dashboardSetId: 3,
          isDisplay: 1,
          sceneName: "大厅",
          shopId: 11620,
          shopName: "合川一食堂",
          sort: 3,
          videoUrl: "",
        },
        {
          channelId: "287ccd0f22a74e4ca12c6b401f60d240",
          channelName: "合川-一食堂-1号门大厅",
          dashboardSetId: 3,
          isDisplay: 1,
          sceneName: "大厅",
          shopId: 11620,
          shopName: "合川一食堂",
          sort: 4,
          videoUrl: "",
        },
      ],
      shopVideoUrl: [
        {
          shopId: 11620,
          url: "https://open.ys7.com/v3/openlive/J84931570_13_2.m3u8?expire=1791355442&id=767035200138911744&t=72fc49b901823e764a0521732dbb7887c8e03a5e14738903214ba8fb7489c7de&ev=100",
          scene: "加工切配",
        },
        {
          shopId: 11620,
          url: "https://open.ys7.com/v3/openlive/AA6605920_15_1.m3u8?expire=1753957165&id=619478423223410688&t=daa583e020cbb045fecc80306a9fd50f880e9029e6ea4321ca52e7b56dc8d803&ev=100",
          scene: "烹饪油锅",
        },
        {
          shopId: 11620,
          url: "https://open.ys7.com/v3/openlive/AA6605920_17_1.m3u8?expire=1753957077&id=619478052438540288&t=523833d26e50a6d84bd5fdc980dcb370c1295ec3c4ec14d0aeecc3158c1775d2&ev=100",
          scene: "售餐间",
        },
        {
          shopId: 11620,
          url: "https://open.ys7.com/v3/openlive/AA6605920_19_1.m3u8?expire=1753956781&id=619476813994786816&t=56322efe328a139b7d5355bff3fdad0ed5aad87640ac28ea937bc459f513cb68&ev=100",
          scene: "洗消间",
        },
        {
          shopId: 11621,
          url: "https://open.ys7.com/v3/openlive/AD6512220_15_1.m3u8?expire=1753957511&id=619479872951037952&t=e987563b9966d7d0cbfd75d55c8de4ebaf1690cd76d715de136274af1b2636b2&ev=100",
          scene: "加工切配",
        },
        {
          shopId: 11621,
          url: "https://open.ys7.com/v3/openlive/AD6512220_55_1.m3u8?expire=1753957472&id=619479712552456192&t=c6acc0fdb426e7dc3a54f1b496b06f438709a5b2e10a551f2054532bb2ca87b7&ev=100",
          scene: "烹饪油锅",
        },
        {
          shopId: 11621,
          url: "https://open.ys7.com/v3/openlive/AD6512220_49_1.m3u8?expire=1753957576&id=619480148793630720&t=ddf3ddd23417b1c343a57a0388a5d8f69b6353311bfca10de307448b349e87d5&ev=100",
          scene: "售餐间",
        },
        {
          shopId: 11621,
          url: "https://open.ys7.com/v3/openlive/AD6512220_47_1.m3u8?expire=1753957678&id=619480575241101312&t=5842a4524d1e74b5317a3db9974df892e59b530ecf72b59f453d8297e58e5ca7&ev=100",
          scene: "洗消间",
        },
        {
          shopId: 11622,
          url: "https://open.ys7.com/v3/openlive/K68559976_4_1.m3u8?expire=1753957881&id=619481425548156928&t=6e40e14c417c34966d73d4e8b853a0b622e5b3ce54683bd2df52c1601fb50620&ev=100",
          scene: "加工切配",
        },
        {
          shopId: 11622,
          url: "https://open.ys7.com/v3/openlive/K68559976_3_1.m3u8?expire=1753957844&id=619481273064226816&t=ce4929fe10bd60a609513e7ce958eefc80caf3c8c950595a3e5ab767b4c562c0&ev=100",
          scene: "烹饪油锅",
        },
        {
          shopId: 11622,
          url: "https://open.ys7.com/v3/openlive/K68559976_8_1.m3u8?expire=1753957777&id=619480991899058176&t=80063d874a3feb5e8e3d99b481fe75767ee5389384c3193ddbf445216ce1b34a&ev=100",
          scene: "售餐间",
        },
        {
          shopId: 11622,
          url: "https://open.ys7.com/v3/openlive/K68559976_6_1.m3u8?expire=1753957813&id=619481141497307136&t=ef3ce2df514fe71a6c85b5c43d573514fb6b34c4ee838e29a9fdcfa046ed7c3b&ev=100",
          scene: "洗消间",
        },
        {
          shopId: 11624,
          url: "https://open.ys7.com/v3/openlive/AD9729822_7_1.m3u8?expire=1753805251&id=618841249041874944&t=c7cbb48bd21ebb405b82e4309f6ce4208db400e36bbdc106e53ed1eb590053de&ev=100",
          scene: "加工切配",
        },
        {
          shopId: 11624,
          url: "https://open.ys7.com/v3/openlive/AD9729822_20_1.m3u8?expire=1753805286&id=618841397729964032&t=74c2d0668e73c5a807af3f78ac2ea451d82de6bf83c1f556e064eee17ce75567&ev=100",
          scene: "烹饪油锅",
        },
        {
          shopId: 11624,
          url: "https://open.ys7.com/v3/openlive/AD9729822_1_1.m3u8?expire=1753805317&id=618841528269283328&t=cda5ed1c76538cdcb64f02234d1be0749b70a49acc5381e9d3561749a493ed49&ev=100",
          scene: "售餐间",
        },
        {
          shopId: 11624,
          url: "https://open.ys7.com/v3/openlive/AD9729822_18_1.m3u8?expire=1753805108&id=618840649292546048&t=9398751501a6fbd8119ce8728367fa00c2067a38b3dd3d146bd9afc25ff6fb8e&ev=100",
          scene: "洗消间",
        },
        {
          shopId: 11702,
          url: "https://open.ys7.com/v3/openlive/AD4811792_6_1.m3u8?expire=1753958503&id=619484035533508608&t=d0560c36a2b1ebf181604e8b675ec6bb646587a562ee6d184baeee3311d46e2e&ev=100",
          scene: "加工切配",
        },
        {
          shopId: 11702,
          url: "https://open.ys7.com/v3/openlive/AD4811792_13_1.m3u8?expire=1753958443&id=619483783422279680&t=23a32969fdd119eb14a292b20aaa560a4c0a445e2306552f3b180ad87437fe31&ev=100",
          scene: "烹饪油锅",
        },
        {
          shopId: 11702,
          url: "https://open.ys7.com/v3/openlive/AD4811792_5_1.m3u8?expire=1753958528&id=619484138205876224&t=6e410612e7e5cb5726f82eb4c393a75f6d6ef5d82939303fbe79e2a4fb85a56f&ev=100",
          scene: "售餐间",
        },
        {
          shopId: 11702,
          url: "https://open.ys7.com/v3/openlive/AD4811792_8_1.m3u8?expire=1753958573&id=619484329711992832&t=4447d1c6d4b6cc081c522001f0e2e519db547d9a95e35c87da5fdcdfd460f309&ev=100",
          scene: "洗消间",
        },
        {
          shopId: 14812,
          url: "https://open.ys7.com/v3/openlive/FH2341856_16_2.m3u8?expire=1788249613&id=754008407979692032&t=33fc6e39deff9151b2d8f462257db3aab2ce6a05559b1e3a92be0c47a960fb00&ev=100",
          scene: "加工切配",
        },
        {
          shopId: 14812,
          url: "https://open.ys7.com/v3/openlive/FH2341856_7_2.m3u8?expire=1788249843&id=754009372107665408&t=603a63e96aed961e6fc2a4bd9a943379d1be00799327aa29ba6feeeefa425743&ev=100",
          scene: "烹饪油锅",
        },
        {
          shopId: 14812,
          url: "https://open.ys7.com/v3/openlive/FH2341856_6_2.m3u8?expire=1788249867&id=754009474448097280&t=46f503b98effab560d388f260c7383295bed9df14f20c64cce797203fbf0f2cd&ev=100",
          scene: "售餐间",
        },
        {
          shopId: 14812,
          url: "https://open.ys7.com/v3/openlive/FH2341856_9_2.m3u8?expire=1788249882&id=754009536561545216&t=3b4fef93a7ed52e23e3ce7e1431d17aaaf59d9bf0c024733987a348f93f9bca4&ev=100",
          scene: "洗消间",
        },
      ],
      // 档口菜品弹窗
      stallAndFoodDialog: false,
      foodTotal: 10,
      foodParams: {
        size: 10,
        current: 1,
        shopId: "",
        stallId: "",
      },
      stallList: [],
      foodList: [],
      stallLoading: false,
      foodLoading: false,
      safeInfo: {
        sceneCnt: 0,
      },
      aiList2: [
        {
          channelCode: "1000216$1$0$2",
          channelId: "1679423965978042370",
          channelName: "192.168.1.36_视频通道_3",
          channelNo: -857831516,
          channelStatus: "1",
          deviceSerial: "1000216",
          deviceSn: "1000216",
          id: "1000216$1$0$2",
          isOnline: true,
          isOpen: 1,
          label: "192.168.1.36_视频通道_3",
          leaf: true,
          type: 2,
          shopId: 101,
          shopName: "知行园餐厅",
          pointName: "加工切配",
          videoid: "video1",
          videoPath:
            "http://192.168.0.3:8090/profile/videos/柳园餐厅洗消间.mp4",
        },
        {
          channelCode: "1000216$1$0$2",
          channelId: "1679423965978042370",
          channelName: "192.168.1.36_视频通道_3",
          channelNo: -857831516,
          channelStatus: "1",
          deviceSerial: "1000216",
          deviceSn: "1000216",
          id: "1000216$1$0$2",
          isOnline: true,
          isOpen: 1,
          label: "192.168.1.36_视频通道_3",
          leaf: true,
          type: 2,
          shopId: 101,
          shopName: "知行园餐厅",
          pointName: "烹饪油锅",
          videoid: "video2",
          videoPath:
            "http://192.168.0.3:8090/profile/videos/柳园餐厅烹饪间.mp4",
        },
        {
          channelCode: "1000216$1$0$2",
          channelId: "1679423965978042370",
          channelName: "192.168.1.36_视频通道_3",
          channelNo: -857831516,
          channelStatus: "1",
          deviceSerial: "1000216",
          deviceSn: "1000216",
          id: "1000216$1$0$2",
          isOnline: true,
          isOpen: 1,
          label: "192.168.1.36_视频通道_3",
          leaf: true,
          type: 2,
          shopId: 101,
          shopName: "知行园餐厅",
          pointName: "售餐间",
          videoid: "video3",
          videoPath:
            "http://192.168.0.3:8090/profile/videos/柳园餐厅售餐厅.mp4",
        },
        {
          channelCode: "1000216$1$0$2",
          channelId: "1679423965978042370",
          channelName: "192.168.1.36_视频通道_3",
          channelNo: -857831516,
          channelStatus: "1",
          deviceSerial: "1000216",
          deviceSn: "1000216",
          id: "1000216$1$0$2",
          isOnline: true,
          isOpen: 1,
          label: "192.168.1.36_视频通道_3",
          leaf: true,
          type: 2,
          shopId: 101,
          shopName: "知行园餐厅",
          pointName: "洗消间",
          videoid: "video4",
          videoPath:
            "http://192.168.0.3:8090/profile/videos/柳园餐厅洗消间.mp4",
        },
      ],
      delScoreList: [],
      monthArr: [
        "",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
        "十二",
      ],
      shopEvent: "扣分事件",
      deleteScroeShow: false,
      deleteType: 1,
      safeScoreItem: undefined,
      healthShow: false,
      healthList: [],
      nongcanShow: false,
      nongList: [],
      healthLoading: false,
      // 供应商
      supplierList: [],
      qrcodeText: "",
      qrcode: undefined,
      // 菜品
      foodData: {
        foodCnt: 0,
        stallCnt: 0,
      },
      mealList: [],
      queryParams: {
        current: 1,
        size: 10,
      },

      // 消息通知
      scrollLeft: 0,
      scrollDelay: 0,
      scrollTimer: null,
      scrollScrollTimer: null,
      noticeScrollWidth: 0,
      noticeClientWidth: 0,
      noticeCnt: 0,
      // 跑马灯
      noticeData: [],
      noticeDataOri: [], //记录当前展示原始数据
      noticeDataNew: [],
      hasNotice: true,
    };
  },
  computed: {
    shopListOption() {
      return this.shopList;
    },
    shopName() {
      const index = this.shopList.findIndex((e) => e.shopId == this.shopId);
      return this.shopList[index].shopName;
    },
    noDealAlarm() {
      let count = 0;
      this.alarmNoticeList.forEach((e) => {
        if (e.isStop != 1) {
          count += 1;
        }
      });
      return count;
    },
  },
  watch: {
    shopId(newValue, oldValue) {
      this.getWisdomMonit();
      // this.getRealAlarmData();
      this.showPoint = false;
      this.nextShopId = oldValue;
      this.showPoint = false;
      setTimeout(() => {
        this.showPoint = true;
      });
      setTimeout(() => {
        this.showPoint = true;
      });
    },
  },
  methods: {
    timePick(val) {
      const date = new Date(val);
      const y = date.getFullYear();
      const m = date.getMonth();
      const d = date.getDate();
      const week = date.getDay();
      const start = new Date(y, m, d - week);
      const end = new Date(y, m, d - week + 6);

      this.weekDate.beginTime = this.dayjs(start).format("YYYY-MM-DD");
      this.weekDate.endTime = this.dayjs(end).format("YYYY-MM-DD");
      this.getWeekDay();
    },
    aiFireSwiperChange(e, num1) {
      this.aiFireShopName = this.aiFireList[e][0].shopName;
      this.$refs[this.aiFireList[num1][0].videoid][0].closeVideo();
      this.$refs[this.aiFireList[num1][1].videoid][0].closeVideo();
    },
    aiSwiperChange(e, num1) {
      this.$refs[this.aiList[num1][0].videoid][0].closeVideo();
      this.$refs[this.aiList[num1][1].videoid][0].closeVideo();
    },
    swiperChange(e) {},
    swiperPrev() {
      this.$refs.aiFireSwiper.prev();
    },
    swiperNext() {
      this.$refs.aiFireSwiper.next();
    },
    // 智慧设备概览弹窗
    deviceDetail() {
      this.deviceDialogShow = true;
      // this.deviceDialogParam.shopId = 106;
      this.getDeviceDialogShopList();
      this.deviceDialogTimer = setInterval(() => {
        this.getDeviceDialogShopList();
      }, 30000);
    },
    closeDeviceDialog() {
      this.deviceDialogShow = false;
      clearInterval(this.deviceDialogTimer);
      this.deviceDialogTimer = null;
    },
    getDeviceDialogShopList() {
      deviceJg().then((res) => {
        this.deviceDialogShopData = res;
        //前端将食堂排序
        let shopItem = this.deviceDialogShopData.splice(5, 1);
        this.deviceDialogShopData.splice(3, 0, shopItem[0]);
        this.deviceDialogParam.shopId = this.deviceDialogParam.shopId
          ? this.deviceDialogParam.shopId
          : res[0].shopId;
        this.getDeviceStatisticsData();
        this.getDeviceDialogWisdomDeviceData();
        this.getYjCenter();
        this.getZhWl(this.deviceDialogParam.shopId);
        this.playVideo();
        // setTimeout(() => {
        //   this.playVideo();
        // }, 1000);
      });
    },
    deviceDialogShopClick(item) {
      clearInterval(this.deviceDialogTimer);
      this.deviceDialogTimer = null;
      this.deviceDialogParam.shopId = item.shopId;
      this.getDeviceStatisticsData();
      this.getDeviceDialogWisdomDeviceData();
      this.getYjCenter();
      this.getZhWl(this.deviceDialogParam.shopId);
      this.deviceDialogTimer = setInterval(() => {
        this.getDeviceDialogShopList();
      }, 30000);
      this.playVideo();
      // setTimeout(() => {
      //   this.playVideo();
      // }, 1000);
    },
    deviceDialogOpen() {},
    playVideo() {
      this.realMonitData = [
        {
          channelId: "287ccd0f22a74e4ca12c6b401f60d240",
          channelName: "合川-一食堂-1号门大厅",
          dashboardSetId: 3,
          isDisplay: 1,
          sceneName: "大厅",
          shopId: 11620,
          shopName: "合川一食堂",
          sort: 1,
          videoUrl: "",
        },
        {
          channelId: "287ccd0f22a74e4ca12c6b401f60d240",
          channelName: "合川-一食堂-1号门大厅",
          dashboardSetId: 3,
          isDisplay: 1,
          sceneName: "大厅",
          shopId: 11620,
          shopName: "合川一食堂",
          sort: 2,
          videoUrl: "",
        },
        {
          channelId: "287ccd0f22a74e4ca12c6b401f60d240",
          channelName: "合川-一食堂-1号门大厅",
          dashboardSetId: 3,
          isDisplay: 1,
          sceneName: "大厅",
          shopId: 11620,
          shopName: "合川一食堂",
          sort: 3,
          videoUrl: "",
        },
        {
          channelId: "287ccd0f22a74e4ca12c6b401f60d240",
          channelName: "合川-一食堂-1号门大厅",
          dashboardSetId: 3,
          isDisplay: 1,
          sceneName: "大厅",
          shopId: 11620,
          shopName: "合川一食堂",
          sort: 4,
          videoUrl: "",
        },
      ];
      realMonit(this.deviceDialogParam.shopId).then((res) => {
        this.realMonitData = res.data;
        this.$nextTick(() => {
          console.log(this.realMonitData, "this.realMonitData");
          this.realMonitData.forEach((e) => {
            var video = $("#video" + e.sort)[0];
            console.log(video, "video");
            switch (e.sort) {
              case 1:
                if (typeof hlsPlayer !== "undefined") {
                  if (hlsPlayer != null) {
                    hlsPlayer.destroy();
                  }
                }
                hlsPlayer = new Hls();
                hlsPlayer.loadSource(e.videoUrl);
                hlsPlayer.attachMedia(video);
                hlsPlayer.on(Hls.Events.MANIFEST_PARSED, function () {
                  video.play();
                });
                break;
              case 2:
                if (typeof hlsPlayer2 !== "undefined") {
                  if (hlsPlayer2 != null) {
                    hlsPlayer2.destroy();
                  }
                }
                hlsPlayer2 = new Hls();
                hlsPlayer2.loadSource(e.videoUrl);
                hlsPlayer2.attachMedia(video);
                hlsPlayer2.on(Hls.Events.MANIFEST_PARSED, function () {
                  video.play();
                });
                break;
              case 3:
                if (typeof hlsPlayer3 !== "undefined") {
                  if (hlsPlayer3 != null) {
                    hlsPlayer3.destroy();
                  }
                }
                hlsPlayer3 = new Hls();
                hlsPlayer3.loadSource(e.videoUrl);
                hlsPlayer3.attachMedia(video);
                hlsPlayer3.on(Hls.Events.MANIFEST_PARSED, function () {
                  video.play();
                });
                break;
              case 4:
                if (typeof hlsPlayer4 !== "undefined") {
                  if (hlsPlayer4 != null) {
                    hlsPlayer4.destroy();
                  }
                }
                hlsPlayer4 = new Hls();
                hlsPlayer4.loadSource(e.videoUrl);
                hlsPlayer4.attachMedia(video);
                hlsPlayer4.on(Hls.Events.MANIFEST_PARSED, function () {
                  video.play();
                });
                break;
              default:
                break;
            }
          });
        });
      });

      return;
      var video = $("#video1")[0];
      if (typeof hlsPlayer !== "undefined") {
        if (hlsPlayer != null) {
          hlsPlayer.destroy();
        }
      }

      var url = this.shopVideoUrl.find(
        (v) =>
          v.shopId == this.deviceDialogParam.shopId && v.scene == "加工切配"
      ).url;
      hlsPlayer = new Hls();
      hlsPlayer.loadSource(url);
      hlsPlayer.attachMedia(video);
      hlsPlayer.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });

      var video2 = $("#video2")[0];
      if (typeof hlsPlayer2 !== "undefined") {
        if (hlsPlayer2 != null) {
          hlsPlayer2.destroy();
        }
      }

      var url2 = this.shopVideoUrl.find(
        (v) =>
          v.shopId == this.deviceDialogParam.shopId && v.scene == "烹饪油锅"
      ).url;
      hlsPlayer2 = new Hls();
      hlsPlayer2.loadSource(url2);
      hlsPlayer2.attachMedia(video2);
      hlsPlayer2.on(Hls.Events.MANIFEST_PARSED, function () {
        video2.play();
      });

      var video3 = $("#video3")[0];
      if (typeof hlsPlayer3 !== "undefined") {
        if (hlsPlayer3 != null) {
          hlsPlayer3.destroy();
        }
      }

      var url3 = this.shopVideoUrl.find(
        (v) => v.shopId == this.deviceDialogParam.shopId && v.scene == "售餐间"
      ).url;
      hlsPlayer3 = new Hls();
      hlsPlayer3.loadSource(url3);
      hlsPlayer3.attachMedia(video3);
      hlsPlayer3.on(Hls.Events.MANIFEST_PARSED, function () {
        video3.play();
      });

      var video4 = $("#video4")[0];
      if (typeof hlsPlayer4 !== "undefined") {
        if (hlsPlayer4 != null) {
          hlsPlayer4.destroy();
        }
      }

      var url4 = this.shopVideoUrl.find(
        (v) => v.shopId == this.deviceDialogParam.shopId && v.scene == "洗消间"
      ).url;
      hlsPlayer4 = new Hls();
      hlsPlayer4.loadSource(url4);
      hlsPlayer4.attachMedia(video4);
      hlsPlayer4.on(Hls.Events.MANIFEST_PARSED, function () {
        video4.play();
      });
    },
    playVideoReal() {
      var video = $("#video1")[0];
      if (typeof hlsPlayer !== "undefined") {
        if (hlsPlayer != null) {
          hlsPlayer.destroy();
        }
      }

      var url = this.shopVideoUrl.find(
        (v) => v.shopId == this.shopDialogParams.shopId && v.scene == "加工切配"
      ).url;
      hlsPlayerReal = new Hls();
      hlsPlayerReal.loadSource(url);
      hlsPlayerReal.attachMedia(video);
      hlsPlayerReal.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });

      var video2 = $("#video2")[0];
      if (typeof hlsPlayerReal2 !== "undefined") {
        if (hlsPlayerReal2 != null) {
          hlsPlayerReal2.destroy();
        }
      }

      var url2 = this.shopVideoUrl.find(
        (v) => v.shopId == this.shopDialogParams.shopId && v.scene == "烹饪油锅"
      ).url;
      hlsPlayerReal2 = new Hls();
      hlsPlayerReal2.loadSource(url2);
      hlsPlayerReal2.attachMedia(video2);
      hlsPlayerReal2.on(Hls.Events.MANIFEST_PARSED, function () {
        video2.play();
      });
    },
    playAiVideo(page) {
      setTimeout(() => {
        this.$refs[this.aiList[page][0].videoid][0].playVideo([
          this.aiList[page][0],
        ]);
        this.$refs[this.aiList[page][1].videoid][0].playVideo([
          this.aiList[page][1],
        ]);
      }, 1000);
    },
    getDeviceStatisticsData() {
      shopStatistics(this.deviceDialogParam).then((res) => {
        this.deviceMonitData = res;
      });
    },
    getDeviceDialogWisdomDeviceData() {
      znDevice(this.deviceDialogParam).then((res) => {
        this.wisdomDataDialog = res;
        const seriesData = this.dealProgressPieData(
          Number(res.onlineRate).toFixed(1),
          Number(res.unlineRate).toFixed(1)
        );
        this.$nextTick(() => {
          this.$refs.devicePieDialog.initChart(
            seriesData,
            res.deviceTotal,
            "设备总数"
          );
        });
      });
    },
    // 安全预警中心
    getYjCenter() {
      this.safeList = [];
      yjCenter(this.deviceDialogParam).then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i += 5) {
            this.safeList.push(res.slice(i, i + 5));
          }
        }
        this.safeList.forEach((e) => {
          e.forEach((e2) => {
            this.$set(
              e2,
              "size",
              e2.shopName.length +
                e2.deviceName.length +
                e2.eventTypeName.length
            );
          });
        });
      });
    },

    getZhWl(shopId) {
      let cards1 = [];
      let cards2 = [];
      Promise.all([
        ygLeace({ sceneId: 13, shopId: shopId }),
        sample({ sceneId: 7, shopId: shopId }),
        warehouse({ sceneId: 6, shopId: shopId }),
        warehouse({ sceneId: 2, shopId: shopId }),
        clothing({ shopId: shopId }),
        kitchen({ sceneId: 37, shopId: shopId }),
        disinfect({ sceneId: 42, shopId: shopId }),
        pesticide({ shopId: shopId }),
      ]).then((res) => {
        let alarm = false;
        if (res[0].operationStatus != "--") {
          const gas = Number(res[0].setGas);
          let gasConcentration = 0;
          const gasArr = res[0].gasConcentration.split("%");
          gasConcentration = Number(gasArr[0]);
          if (gasConcentration > gas) {
            alarm = true;
          }
        }
        cards1[0] = {
          label: "后厨油锅燃气监管",
          title1: "油锅温度",
          value1: res[0].temp != "--" ? res[0].temp + "℃" : res[0].temp,
          title2: "监管现场",
          value2: res[0].status ? res[0].status : "--",
          title3: "燃气浓度",
          value3:
            res[0].gasConcentration == "0.0%" ? "0%" : res[0].gasConcentration,
          deviceNum: res[0].deviceNum,
          isAlarm:
            ((res[0].status == "无人" || res[0].status == "危险") &&
              res[0].deviceNum != "--" &&
              Number(res[0].deviceNum.split("/")[0]) > 0) ||
            (res[0].operationStatus != "--" && alarm)
              ? true
              : false,
          shopName: res[0].shopName,
          operationStatus: res[0].operationStatus,
        };
        cards1[1] = {
          label: "留样监管",
          title1: "留样进度",
          value1: res[1].mealType,
          title2: "留样温度",
          value2: res[1].temp != "--" ? res[1].temp + "℃" : res[1].temp,
          deviceNum: res[1].deviceNum,
          isAlarm: res[1].alarmCnt > 0 ? true : false,
          shopName: res[1].shopName,
          operationStatus: res[1].operationStatus,
        };
        cards1[2] = {
          label: "主食仓库",
          title1: "挡鼠门",
          value1: res[2].ratStatus,
          title2: "防潮光栅",
          value2: res[2].gratingStatus,
          deviceNum: res[2].deviceNum,
          temperature:
            res[2].temperature != "--" && res[2].temperature
              ? res[2].temperature
              : !res[2].temperature
              ? "--"
              : res[2].temperature,
          humidity:
            res[2].humidity != "--" && res[2].humidity
              ? res[2].humidity
              : !res[2].humidity
              ? "--"
              : res[2].humidity,
          isAlarm:
            ((res[2].gratingStatus == "危险" || res[2].ratStatus == "危险") &&
              res[2].deviceNum != "--" &&
              Number(res[2].deviceNum.split("/")[0]) > 0) ||
            res[2].tempHumiAlarm > 0
              ? true
              : false,
          shopName: res[2].shopName,
          operationStatus: res[2].operationStatus,
        };

        cards1[3] = {
          label: "副食仓库",
          title1: "挡鼠门",
          value1: res[3].ratStatus,
          isAlarm:
            (res[3].ratStatus == "危险" &&
              res[3].deviceNum != "--" &&
              Number(res[3].deviceNum.split("/")[0]) > 0) ||
            res[3].tempHumiAlarm > 0
              ? true
              : false,
          deviceNum: res[3].deviceNum,
          temperature:
            res[3].temperature != "--"
              ? res[3].temperature
              : res[3].temperature,
          humidity: res[3].humidity != "--" ? res[3].humidity : res[3].humidity,

          shopName: res[3].shopName,
          operationStatus: res[3].operationStatus,
        };
        cards2[0] = {
          label: "着装规范",
          title1: "预警",
          value1: res[4].alarmTotal,
          deviceNum: res[4].deviceNum,
          isAlarm:
            res[4].alarmTotal > 0 &&
            res[4].deviceNum != "--" &&
            Number(res[4].deviceNum.split("/")[0]) > 0
              ? true
              : false,
          shopName: res[4].shopName,
          operationStatus: res[4].operationStatus,
        };
        cards2[1] = {
          label: "后厨出入监管",
          title1: "晨检",
          value1: res[5].checkStatus,
          title2: "陌生人",
          value2:
            res[5].strangerAlarmNum > 0
              ? res[5].strangerAlarmNum
              : res[5].strangerStatus,
          deviceNum: res[5].deviceNum,
          isAlarm:
            res[5].strangerAlarmNum > 0 &&
            res[5].deviceNum != "--" &&
            Number(res[5].deviceNum.split("/")[0]) > 0
              ? true
              : false,
          shopName: res[5].shopName,
          operationStatus: res[5].operationStatus,
        };
        cards2[2] = {
          label: "环境消毒",
          title1: "消毒状态",
          value1: res[6].status,
          title2: res[6].status == "未消毒" ? "累计时长" : "消毒时长",
          value2:
            res[6].continueTime != "--"
              ? res[6].continueTime + "min"
              : res[6].continueTime,
          deviceNum: res[6].deviceNum,
          isAlarm: false,
          shopName: res[6].shopName,
          operationStatus: res[6].operationStatus,
        };
        cards2[3] = {
          label: "食材农残检测",
          title1: "食材结果",
          value1: res[7].status,
          deviceNum: res[7].deviceNum,
          isAlarm: false,
          shopName: res[7].shopName,
          operationStatus: res[7].operationStatus,
        };
        this.mainSwiperList = [
          { cards1: cards1, cards2: cards2, shopId: shopId },
        ];
        console.log(this.mainSwiperList, "this.mainSwiperList");
      });

      return;
      ygLeace({ sceneId: 13, shopId: shopId }).then((res) => {
        let alarm = false;
        if (res.operationStatus != "--") {
          const gas = Number(res.setGas);
          let gasConcentration = 0;
          const gasArr = res.gasConcentration.split("%");
          gasConcentration = Number(gasArr[0]);
          if (gasConcentration > gas) {
            alarm = true;
          }
        }
        cards1[0] = {
          label: "后厨油锅燃气监管",
          title1: "油锅温度",
          value1: res.temp != "--" ? res.temp + "℃" : res.temp,
          title2: "监管现场",
          value2: res.status ? res.status : "--",
          title3: "燃气浓度",
          value3: res.gasConcentration == "0.0%" ? "0%" : res.gasConcentration,
          deviceNum: res.deviceNum,
          isAlarm:
            ((res.status == "无人" || res.status == "危险") &&
              res.deviceNum != "--" &&
              Number(res.deviceNum.split("/")[0]) > 0) ||
            (res.operationStatus != "--" && alarm)
              ? true
              : false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        };
        // this.$set(cards1, 0, {
        //   label: "后厨油锅燃气监管",
        //   title1: "油锅温度",
        //   value1: res.temp != "--" ? res.temp + "℃" : res.temp,
        //   title2: "监管现场",
        //   value2: res.status ? res.status : "--",
        //   title3: "燃气浓度",
        //   value3: res.gasConcentration == "0.0%" ? "0%" : res.gasConcentration,
        //   deviceNum: res.deviceNum,
        //   isAlarm:
        //     ((res.status == "无人" || res.status == "危险") &&
        //       res.deviceNum != "--" &&
        //       Number(res.deviceNum.split("/")[0]) > 0) ||
        //     (res.operationStatus != "--" && alarm)
        //       ? true
        //       : false,
        //   shopName: res.shopName,
        //   operationStatus: res.operationStatus,
        // });
      });
      sample({ sceneId: 7, shopId: shopId }).then((res) => {
        cards1[1] = {
          label: "留样监管",
          title1: "留样进度",
          value1: res.mealType,
          title2: "留样温度",
          value2: res.temp != "--" ? res.temp + "℃" : res.temp,
          deviceNum: res.deviceNum,
          isAlarm: res.alarmCnt > 0 ? true : false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        };
        // this.$set(cards1, 1, {
        //   label: "留样监管",
        //   title1: "留样进度",
        //   value1: res.mealType,
        //   title2: "留样温度",
        //   value2: res.temp != "--" ? res.temp + "℃" : res.temp,
        //   deviceNum: res.deviceNum,
        //   isAlarm: res.alarmCnt > 0 ? true : false,
        //   shopName: res.shopName,
        //   operationStatus: res.operationStatus,
        // });
      });
      warehouse({ sceneId: 6, shopId: shopId }).then((res) => {
        cards1[2] = {
          label: "主食仓库",
          title1: "挡鼠门",
          value1: res.ratStatus,
          title2: "防潮光栅",
          value2: res.gratingStatus,
          deviceNum: res.deviceNum,
          temperature:
            res.temperature != "--" && res.temperature
              ? res.temperature
              : !res.temperature
              ? "--"
              : res.temperature,
          humidity:
            res.humidity != "--" && res.humidity
              ? res.humidity
              : !res.humidity
              ? "--"
              : res.humidity,
          isAlarm:
            ((res.gratingStatus == "危险" || res.ratStatus == "危险") &&
              res.deviceNum != "--" &&
              Number(res.deviceNum.split("/")[0]) > 0) ||
            res.tempHumiAlarm > 0
              ? true
              : false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        };
        // this.$set(cards1, 2, {
        //   label: "主食仓库",
        //   title1: "挡鼠门",
        //   value1: res.ratStatus,
        //   title2: "防潮光栅",
        //   value2: res.gratingStatus,
        //   deviceNum: res.deviceNum,
        //   temperature:
        //     res.temperature != "--" && res.temperature
        //       ? res.temperature
        //       : !res.temperature
        //       ? "--"
        //       : res.temperature,
        //   humidity:
        //     res.humidity != "--" && res.humidity
        //       ? res.humidity
        //       : !res.humidity
        //       ? "--"
        //       : res.humidity,
        //   isAlarm:
        //     ((res.gratingStatus == "危险" || res.ratStatus == "危险") &&
        //       res.deviceNum != "--" &&
        //       Number(res.deviceNum.split("/")[0]) > 0) ||
        //     res.tempHumiAlarm > 0
        //       ? true
        //       : false,
        //   shopName: res.shopName,
        //   operationStatus: res.operationStatus,
        // });
      });
      warehouse({ sceneId: 2, shopId: shopId }).then((res) => {
        cards1[3] = {
          label: "副食仓库",
          title1: "挡鼠门",
          value1: res.ratStatus,
          isAlarm:
            (res.ratStatus == "危险" &&
              res.deviceNum != "--" &&
              Number(res.deviceNum.split("/")[0]) > 0) ||
            res.tempHumiAlarm > 0
              ? true
              : false,
          deviceNum: res.deviceNum,
          temperature:
            res.temperature != "--" ? res.temperature : res.temperature,
          humidity: res.humidity != "--" ? res.humidity : res.humidity,

          shopName: res.shopName,
          operationStatus: res.operationStatus,
        };
        // this.$set(cards1, 3, {
        //   label: "副食仓库",
        //   title1: "挡鼠门",
        //   value1: res.ratStatus,
        //   isAlarm:
        //     (res.ratStatus == "危险" &&
        //       res.deviceNum != "--" &&
        //       Number(res.deviceNum.split("/")[0]) > 0) ||
        //     res.tempHumiAlarm > 0
        //       ? true
        //       : false,
        //   deviceNum: res.deviceNum,
        //   temperature:
        //     res.temperature != "--" ? res.temperature : res.temperature,
        //   humidity: res.humidity != "--" ? res.humidity : res.humidity,

        //   shopName: res.shopName,
        //   operationStatus: res.operationStatus,
        // });
      });
      clothing({ shopId: shopId }).then((res) => {
        cards2[0] = {
          label: "着装规范",
          title1: "预警",
          value1: res.alarmTotal,
          deviceNum: res.deviceNum,
          isAlarm:
            res.alarmTotal > 0 &&
            res.deviceNum != "--" &&
            Number(res.deviceNum.split("/")[0]) > 0
              ? true
              : false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        };
        // this.$set(cards2, 0, {
        //   label: "着装规范",
        //   title1: "预警",
        //   value1: res.alarmTotal,
        //   deviceNum: res.deviceNum,
        //   isAlarm:
        //     res.alarmTotal > 0 &&
        //     res.deviceNum != "--" &&
        //     Number(res.deviceNum.split("/")[0]) > 0
        //       ? true
        //       : false,
        //   shopName: res.shopName,
        //   operationStatus: res.operationStatus,
        // });
      });
      kitchen({ sceneId: 37, shopId: shopId }).then((res) => {
        cards2[1] = {
          label: "后厨出入监管",
          title1: "晨检",
          value1: res.checkStatus,
          title2: "陌生人",
          value2:
            res.strangerAlarmNum > 0
              ? res.strangerAlarmNum
              : res.strangerStatus,
          deviceNum: res.deviceNum,
          isAlarm:
            res.strangerAlarmNum > 0 &&
            res.deviceNum != "--" &&
            Number(res.deviceNum.split("/")[0]) > 0
              ? true
              : false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        };
        // this.$set(cards2, 1, {
        //   label: "后厨出入监管",
        //   title1: "晨检",
        //   value1: res.checkStatus,
        //   title2: "陌生人",
        //   value2:
        //     res.strangerAlarmNum > 0
        //       ? res.strangerAlarmNum
        //       : res.strangerStatus,
        //   deviceNum: res.deviceNum,
        //   isAlarm:
        //     res.strangerAlarmNum > 0 &&
        //     res.deviceNum != "--" &&
        //     Number(res.deviceNum.split("/")[0]) > 0
        //       ? true
        //       : false,
        //   shopName: res.shopName,
        //   operationStatus: res.operationStatus,
        // });
      });
      disinfect({ sceneId: 42, shopId: shopId }).then((res) => {
        // this.$set(cards2, 2, {
        //   label: "环境消毒",
        //   title1: "消毒状态",
        //   value1: res.status,
        //   title2: res.status == "未消毒" ? "累计时长" : "消毒时长",
        //   value2:
        //     res.continueTime != "--"
        //       ? res.continueTime + "min"
        //       : res.continueTime,
        //   deviceNum: res.deviceNum,
        //   isAlarm: false,
        //   shopName: res.shopName,
        //   operationStatus: res.operationStatus,
        // });
        cards2[2] = {
          label: "环境消毒",
          title1: "消毒状态",
          value1: res.status,
          title2: res.status == "未消毒" ? "累计时长" : "消毒时长",
          value2:
            res.continueTime != "--"
              ? res.continueTime + "min"
              : res.continueTime,
          deviceNum: res.deviceNum,
          isAlarm: false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        };
      });
      pesticide({ shopId: shopId }).then((res) => {
        // this.$set(cards2, 3, {
        //   label: "食材农残检测",
        //   title1: "食材结果",
        //   value1: res.status,
        //   deviceNum: res.deviceNum,
        //   isAlarm: false,
        //   shopName: res.shopName,
        //   operationStatus: res.operationStatus,
        // });
        cards2[3] = {
          label: "食材农残检测",
          title1: "食材结果",
          value1: res.status,
          deviceNum: res.deviceNum,
          isAlarm: false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        };
      });

      this.mainSwiperList = [
        { cards1: cards1, cards2: cards2, shopId: shopId },
      ];
      console.log(this.mainSwiperList, "this.mainSwiperList");
    },
    // 周排查
    getWeekDay() {
      weekRecord(this.weekDate.endTime).then((res) => {
        const data = res.data.splice(0, 6);
        //前端将食堂排序
        let shopItem = data.splice(5, 1);
        data.splice(3, 0, shopItem[0]);
        data.forEach((e, index) => {
          e.rate =
            Number(e.problems) == 0 || e.problems == "--"
              ? 0
              : ((Number(e.resolved) / Number(e.problems)) * 100).toFixed(0);
          e.index = index + 1;
        });
        const dataCopy = JSON.parse(JSON.stringify(data));
        const length = data.length / 3;
        this.weekData = [];
        for (let i = 0; i < length; i++) {
          this.weekData.push(data.splice(0, 3));
        }

        this.$nextTick(() => {
          this.$refs["weekswiper"].setActiveItem(0);
          this.showDayPie("week1", { score: dataCopy[0].rate });
          this.showDayPie("week2", { score: dataCopy[1].rate });
          this.showDayPie("week3", { score: dataCopy[2].rate });
          this.showDayPie("week4", { score: dataCopy[3].rate });
          this.showDayPie("week5", { score: dataCopy[4].rate });
          this.showDayPie("week6", { score: dataCopy[5].rate });
        });
      });
    },
    showDialog(item) {
      if (item.recordId == "--" || !item.recordId) {
        return;
      }
      this.weekDialogShow = true;
      this.weekTab = 1;
      this.weekParams.shopId = "";
      this.weekParams.structureId = 703;
      this.weekTitle =
        this.dayjs(this.weekDate.beginTime).format("YYYY年MM月DD日") +
        "~" +
        this.dayjs(this.weekDate.endTime).format("MM月DD日") +
        " 每周食品安全排查治理报告";
      this.weekParams.recordId = item.recordId;
      this.getWeekDetail();
    },
    getWeekDetail() {
      weekDetail(this.weekParams.recordId).then((res) => {
        this.weekDetailData = res.data;
        this.getRectifyData();
      });
    },
    weekTabFun(tab) {
      if (tab == this.weekTab) {
        return;
      }
      this.weekTab = tab;
    },
    nodeClick(node) {
      if (node.type == 3) {
        this.weekParams.structureId = "";
        this.weekParams.shopId = node.id;
      } else {
        this.weekParams.shopId = "";
        this.weekParams.structureId = node.id;
      }
      this.getRectifyData();
    },
    getRectifyData() {
      weekRectify(this.weekParams).then((res) => {
        res.data.forEach((e) => {
          if (e.checkWay == 10) {
            if (e.checkType == 1) {
              e.checkTitle = "督查巡检";
            } else if (e.checkType == 2) {
              e.checkTitle = "门店自检";
            } else if (e.checkType == 3) {
              e.checkTitle = "任务巡检";
            }
          } else if (e.checkWay == 20) {
            if (e.checkType == 1) {
              e.checkTitle = "视频巡检";
            } else if (e.checkType == 2) {
              e.checkTitle = "视频自检";
            }
          } else {
            e.checkTitle = "日管控";
          }
        });
        res.data.forEach((e) => {
          const checkRemarkList = e.checkPicture
            ? e.checkPicture.split(",")
            : [];
          const checkImgList = {
            data: [],
          };
          checkRemarkList.forEach((e, index) => {
            checkImgList.data.push({
              imgUrl: e,
              index: index,
            });
          });

          const checkRectifyList = e.rectifiedImgs
            ? e.rectifiedImgs.split(",")
            : [];
          const rectifyImgList = {
            data: [],
          };
          checkRectifyList.forEach((e, index) => {
            rectifyImgList.data.push({
              imgUrl: e,
              index: index,
            });
          });
          e.checkImgList = checkImgList;
          e.rectifyImgList = rectifyImgList;
        });
        this.weekRectifyData = res.data;
      });
    },
    nextDay() {
      const nextDate = this.dayjs(this.date).add(1, "d").format("YYYY-MM-DD");
      const nowDate = this.dayjs().format("YYYY-MM-DD");
      if (nextDate > nowDate) {
        return;
      }
      this.date = nextDate;
      this.getDayData(this.date);
    },
    prevDay() {
      const nextDate = this.dayjs(this.date).add(-1, "d").format("YYYY-MM-DD");
      const serverDate = this.dayjs().add(-6, "d").format("YYYY-MM-DD");
      if (nextDate < serverDate) {
        return;
      }

      this.date = nextDate;
      this.getDayData(this.date);
    },
    prevWeek() {
      this.weekDate.beginTime = this.dayjs(
        this.dayjs(this.weekDate.beginTime).add(-7, "d")
      ).format("YYYY-MM-DD");
      this.weekDate.endTime = this.dayjs(
        this.dayjs(this.weekDate.endTime).add(-7, "d")
      ).format("YYYY-MM-DD");
      this.getWeekDay();
    },
    nextWeek() {
      const nextWeek = this.dayjs(
        this.dayjs(this.weekDate.endTime).add(7, "d")
      ).format("YYYY-MM-DD");
      if (nextWeek > this.dayjs().format("YYYY-MM-DD")) {
        return;
      }
      this.weekDate.beginTime = this.dayjs(
        this.dayjs(this.weekDate.beginTime).add(7, "d")
      ).format("YYYY-MM-DD");
      this.weekDate.endTime = this.dayjs(
        this.dayjs(this.weekDate.endTime).add(7, "d")
      ).format("YYYY-MM-DD");
      this.getWeekDay();
    },
    // 月考核
    showDayPie(ref, data) {
      const color = "#58B7FF";
      const score = data.score;
      let option = {
        title: {
          text: score + "%",
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#BCD2ED",
            fontFamily: "DINCondensed-Bold-Bold",
            fontSize: 14 * this.widthPix,
          },
        },
        series: [
          {
            name: "Line 1",
            type: "pie",
            clockWise: true,
            radius: ["80%", "100%"],
            hoverAnimation: false,
            color: ["rgba(67, 96, 122, 0.6)"],
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            // hoverAnimation: false,
            data: [
              {
                value: score,
                name: "01",
                itemStyle: {
                  color: {
                    // 完成的圆环的颜色
                    colorStops: [
                      {
                        offset: 0,
                        color: color, // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: color, // 100% 处的颜色
                      },
                    ],
                  },
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                },
              },
              {
                name: "02",
                value: 100 - score,
              },
            ],
          },
        ],
      };
      let chart = this.$refs[ref][0];
      let myChart;
      if (chart) {
        if (myChart != null && myChart != "" && myChart != undefined) {
          myChart.dispose();
        }
        myChart = echarts.init(chart);
        myChart.clear();
        option && myChart.setOption(option);
      }
    },
    // 1.4.0新增
    // 月调度
    getMonthRecord() {
      monthRecord().then((res) => {
        this.monthData = res.data;
        if (this.monthData.length > 0 && this.monthData.length < 6) {
          let nodataArr = this.monthData.length;
          if (this.monthData.length > 3) {
            nodataArr = Array(6 - this.monthData.length).fill({
              nodataFlag: true,
            });
          } else {
            nodataArr = Array(3 - this.monthData.length).fill({
              nodataFlag: true,
            });
          }
          this.monthData.push(...nodataArr);
        }
        if (this.monthData.length > 3) {
          this.monthData = [this.monthData.splice(0, 3), this.monthData];
        } else if (this.monthData.length > 0) {
          this.monthData = [this.monthData.splice(0, 3)];
        } else {
          this.monthData = [];
        }
      });
    },
    conversionHeight(arr, index) {
      let height = 0;
      for (let i = 0; i <= index; i++) {
        height += arr[i];
      }
      return height;
    },
    showMonthDialog(item = "") {
      this.monthDetailData = JSON.parse(JSON.stringify(item));

      this.monthDetailData.contentOne = this.monthDetailData.contentOne;
      this.monthDetailData.contentOneName = this.monthDetailData.contentOneName;
      this.monthDetailData.contentOneUrl = this.monthDetailData.contentOneUrl;
      this.monthDetailData.otherFile = [];
      const attrList = [
        "",
        "",
        "contentTwo",
        "contentThree",
        "contentFour",
        "contentFive",
        "contentSix",
        "contentSeven",
        "contentEight",
        "contentNine",
        "contentTen",
        "contentEleven",
      ];
      const attrNameList = [
        "",
        "",
        "contentTwoName",
        "contentThreeName",
        "contentFourName",
        "contentFiveName",
        "contentSixName",
        "contentSevenName",
        "contentEightName",
        "contentNineName",
        "contentTenName",
        "contentElevenName",
      ];
      const attrUrlList = [
        "",
        "",
        "contentTwoUrl",
        "contentThreeUrl",
        "contentFourUrl",
        "contentFiveUrl",
        "contentSixUrl",
        "contentSevenUrl",
        "contentEightUrl",
        "contentNineUrl",
        "contentTenUrl",
        "contentElevenUrl",
      ];
      const hiddenIndex = this.monthDetailData.hiddenItems.split(",");
      const otherFile = [];
      attrList.forEach((e, index) => {
        if (e) {
          otherFile.push({
            content: this.monthDetailData[e],
            contentName: this.monthDetailData[attrNameList[index]],
            contentUrl: this.monthDetailData[attrUrlList[index]],
            show: true,
          });
        }
      });
      otherFile.forEach((e, index) => {
        const indexReal = index + 2;
        if (e.contentUrl) {
          if (hiddenIndex.includes(indexReal + "")) {
            e.show = false;
          }
          this.monthDetailData.otherFile.push(e);
        }
      });
      this.viewFileUrl = this.monthDetailData.contentOne;
      const fileData = {
        fileUrl: this.monthDetailData.contentOneUrl,
        htmlData: this.viewFileUrl,
        widthPix: this.widthPix,
      };
      localStorage.setItem("fileData", JSON.stringify(fileData));
      this.monthDialogShow = true;
      this.currentFile = 1;
    },
    viewFile(type, index) {
      if (type == 1) {
        this.viewFileUrl = this.monthDetailData.contentOne;
        const fileData = {
          fileUrl: this.monthDetailData.contentOneUrl,
          htmlData: this.viewFileUrl,
          widthPix: this.widthPix,
        };
        localStorage.setItem("fileData", JSON.stringify(fileData));
        this.currentFile = 1;
      } else {
        this.viewFileUrl = this.monthDetailData.otherFile[index].content;
        const fileData = {
          fileUrl: this.monthDetailData.otherFile[index].contentUrl,
          htmlData: this.viewFileUrl,
          widthPix: this.widthPix,
        };
        localStorage.setItem("fileData", JSON.stringify(fileData));
        this.currentFile = type + index;
      }
    },
    monthLeftClick(current) {
      let monthRight = document.querySelector(".month-right_wrap");
      this.showCurrent = current;
      const hiddenLength = this.monthDetailData.hiddenItems.split(",").length;
      if (current == 1) {
        monthRight.scrollTop = 0;
      } else {
        monthRight.scrollTop = this.operateHeight[current - 1 - hiddenLength];
      }
    },
    monthRighScroll(e) {
      const scrollTop = e.target.scrollTop;
      const current = this.operateHeight.findIndex((e) => scrollTop < e);
      this.showCurrent = current + 1;
    },
    // 日管控
    getDayData() {
      dayRecord(this.date).then((res) => {
        const data = res.data.splice(0, 6);
        //前端将食堂排序
        let shopItem = data.splice(5, 1);
        data.splice(3, 0, shopItem[0]);

        data.forEach((e) => {
          if (e.status == 2) {
            e.checkTotal = 0;
          } else {
            e.checkTotal =
              Number(e.pass) + Number(e.unqualify) + Number(e.passMiss);
          }
          e.rate = ((e.checkTotal / Number(e.total)) * 100).toFixed(2);
        });
        this.dayRecordData = [data.splice(0, 3), data];
      });
    },
    // 日管控详情
    showDayDialog(item) {
      this.dayDialogShow = true;
      this.dayShopId = item.shopId;
      this.dayTitle =
        this.dayjs().add(-1, "d").format("YYYY年MM月DD日") +
        " 每日食品安全检查记录";
      this.getDayDialogLeftData();
    },
    getDayDialogLeftData() {
      this.dayLeftLoading = true;
      dayLeftList(this.date).then((res) => {
        this.dayLeftData = res.data.splice(0, 6);
        //前端将食堂排序
        let shopItem = this.dayLeftData.splice(5, 1);
        this.dayLeftData.splice(3, 0, shopItem[0]);
        const filterData = this.dayLeftData.filter(
          (e) => e.shopId == this.dayShopId
        );
        this.dayRecordId = filterData[0].dailyControlRecordId;
        this.dayLeftLoading = false;
        this.dayRightData = [];
        this.getDayDialogRightData(this.dayRecordId, this.dayShopId);
      });
    },
    getPassAndUnqualify(data) {
      let pass = 0;
      data.forEach((e) => {
        if (e.status == 1) {
          pass += 1;
        }
      });
      return pass;
    },
    conversionData(data) {
      const categorizedData = [];
      const typeToTitleMap = {};
      data.forEach((item, index) => {
        item.index = index + 1;
        // 首次遇到某个topicType时，记录其topicName作为topicTitle
        if (!typeToTitleMap[item.topicType]) {
          typeToTitleMap[item.topicType] = item.topicTypeName;
        }
      });
      Object.keys(typeToTitleMap).forEach((topicType) => {
        // 为每个topicType创建一个新对象，并收集所有匹配的item到data数组中
        const groupedItems = data.filter((item) => item.topicType == topicType);
        categorizedData.push({
          topicType: topicType,
          topicTitle: typeToTitleMap[topicType],
          data: groupedItems,
        });
      });
      categorizedData.forEach((e, index) => {
        // e.topicTitle = `${this.numberToChinese(index+1)}、${e.topicTitle}`
        e.topicTitle = `${e.topicTitle}`;
      });
      this.dayRightData = categorizedData;
    },
    numberToChinese(num) {
      if (num === 0) return this.digitToChinese[num];
      let chineseStr = "";
      // 处理百位和十位
      if (num >= 100) {
        const hundred = Math.floor(num / 100);
        chineseStr += this.numberToChinese(hundred) + this.digitToChinese[100];
        num %= 100;
      }

      // 处理特殊情况，如“十”、“二十”
      if (this.specialDigits[num]) {
        chineseStr += this.specialDigits[num];
        num = 0;
      } else {
        // 处理个位和十位
        if (num >= 10) {
          const ten = Math.floor(num / 10);
          chineseStr += this.digitToChinese[ten * 10];
          num %= 10;
        }
        if (num > 0) {
          chineseStr += this.digitToChinese[num];
        }
      }

      // 移除多余的“零”，但保留“一十”、“一百”等
      chineseStr = chineseStr.replace(/零+/g, "零").replace(/^一零+/, "一");

      return chineseStr;
    },
    getDayDialogRightData(id, shopId) {
      this.dayRightLoading = true;
      this.dayShopId = shopId;
      this.dayRightData = [];
      if (!id) {
        this.dayRightLoading = false;
        return;
      }
      dayRightList(id).then((res) => {
        this.conversionData(res.data);
        this.dayRightLoading = false;
      });
    },
    qrcodeCallback(dataUrl, id) {
      this.qrcode = dataUrl;
    },
    shopMapClick(item) {
      this.shopDialogShow = true;
      this.shopDialogParams.shopId = item.shopId;
      this.shopDialogParams.shopName = item.shopName;
      this.foodParams.shopId = item.shopId;
      this.foodParams.current = 1;
      this.shopDialogCurrent = 1;

      const cancelToken = this.$store.getters.cancelTokens;
      if (cancelToken.length) {
        for (const cancelFn of cancelToken) {
          cancelFn();
        }
      }
      this.qrcodeText =
        "https://dobaystaffh5.anasit.com/supplier/list" +
        "?shopInfo=" +
        JSON.stringify({
          shopId: item.shopId,
          shopName: item.shopName,
          shopAddress: item.shopAddress,
        });
      this.$store.dispatch("clearCancel");
      this.getshopGl();
      this.getmorningCheckInfo();
      this.getmorningCheckTable();
      this.getZhWlDialogShop();
      this.getlyRate();
      this.getlyPic();
      this.getlyRecordList();
      this.getFoodSafetyIndexDataOnShopDialog();
      this.getfoodData();
      this.getsupplier();
      this.getmealShow();
      this.getshopStaffHealth();
      this.getDayControl();
      // this.playVideoReal();
    },
    shopDialogShopClick(item) {
      this.shopDialogParams.shopId = item.shopId;
      this.shopDialogParams.shopName = item.shopName;
      this.foodParams.shopId = item.shopId;
      this.foodParams.current = 1;
      const cancelToken = this.$store.getters.cancelTokens;
      if (cancelToken.length) {
        for (const cancelFn of cancelToken) {
          cancelFn();
        }
      }
      this.$store.dispatch("clearCancel");
      this.qrcodeText =
        "https://dobaystaffh5.anasit.com/supplier/list" +
        "?shopInfo=" +
        JSON.stringify({
          shopId: item.shopId,
          shopName: item.shopName,
          shopAddress: item.shopAddress,
        });
      this.getsupplier();
      this.getfoodData();
      this.getmealShow();
      this.getshopGl();
      this.getmorningCheckInfo();
      this.getmorningCheckTable();
      this.getZhWlDialogShop();
      this.getlyRate();
      this.getlyPic();
      this.getlyRecordList();
      this.getshopStaffHealth();
      this.getDayControl();
      // this.playVideoReal();
    },
    getfoodData() {
      FdData({ shopId: this.shopDialogParams.shopId }).then((res) => {
        if (res.code == 200) {
          this.foodData = res.data;
        }
      });
    },
    getmealShow() {
      this.mealList = [];
      mealShow({ shopId: this.shopDialogParams.shopId }).then((res) => {
        if (res.code == 200) {
          for (let i = 0; i < res.data.length; i += 6) {
            this.mealList.push(res.data.slice(i, i + 6));
          }
        }
      });
    },
    getsupplier() {
      this.supplierList = [];
      supplier({ shopId: this.shopDialogParams.shopId }).then((res) => {
        if (res.code == 200) {
          for (let i = 0; i < res.data.length; i += 3) {
            this.supplierList.push(res.data.slice(i, i + 3));
          }
        }
      });
    },
    // 食堂概览
    showliuY(item) {
      this.liuyangShow = true;
      this.queryParams.current = 1;
      this.queryParams.size = 10;
      this.queryParams.createTime = item.createTime;
      this.queryParams.mealType = item.mealType;
      this.queryParams.shopId = item.shopId;

      this.getlymealLi();
      this.getlyPicLi(item);
    },
    getlymealLi() {
      this.tableLoading = true;
      this.lyDetailList = [];
      lymealLi(this.queryParams).then((res) => {
        if (res.code == 200) {
          this.lyDetailList = res.data.records;
          if (this.lyDetailList.length > 0 && this.lyDetailList.length < 10) {
            const nodataList = Array(10 - this.lyDetailList.length).fill({
              fillFlag: true,
            });
            this.lyDetailList = this.lyDetailList.concat(nodataList);
          }
          this.total = res.data.total;
          this.tableLoading = false;
        }
      });
    },
    getlyPicLi(item) {
      this.lyDetailPicList = [];
      this.shopPicLoading = true;

      lyPicLi(item).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            let arr =
              res.data.indexOf(",") != -1 ? res.data.split(",") : [res.data];
            if (arr.length > 0) {
              arr = arr.map((e) => {
                return this.imgUrl + e;
              });
            }
            this.imagReviewList = arr;
            for (let i = 0; i < arr.length; i += 4) {
              this.lyDetailPicList.push(arr.slice(i, i + 4));
            }
          }
          this.shopPicLoading = false;
        }
      });
    },
    // 日管控
    showDialogTab(current) {
      if (current == this.shopDialogCurrent) {
        return;
      }
      this.shopDialogCurrent = current;
      if (current == 1) {
        this.getmorningCheckInfo();
      }
    },
    async getDayControl() {
      this.showScroll = false;
      await dayInfo({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.dayCards[0] = {
          label1: "食品经营许可证",
          value1: res.data.licenceStatus,
          label1Color: res.data.licenceStatus != "正常经营" ? "#FFAA3B" : "",
          label2: "明厨亮灶",
          value2: res.data.cookStatus,
          label2Color: res.data.cookStatus != "正常公示" ? "#FFAA3B" : "",
          label3: "菜单公示",
          value3: res.data.foodStatus,
          label3Color: res.data.foodStatus != "正常公示" ? "#FFAA3B" : "",
          showPop1: false,
          showPop2: false,
          showPop3: false,
        };
      });
      await staffControl({ shopId: this.shopDialogParams.shopId }).then(
        (res) => {
          this.dayCards[1] = {
            label1: "健康证",
            value1: res.data.status,
            label1Color: res.data.status != "健康合格" ? "#FFAA3B" : "",
            label2: "晨检",
            value2:
              res.data.staffCnt == 0 ? "--" : res.data.staffCnt + "人正常",
            label2Color: res.data.staffCnt == 0 ? "#FFAA3B" : "",
            label3: "着装行为",
            value3: res.data.alarmStatus,
            label3Color: res.data.alarmStatus != "符合要求" ? "#FFAA3B" : "",
            showPop1: false,
            showPop2: false,
            showPop3: false,
          };
        }
      );
      await workEnvDay({ shopId: this.shopDialogParams.shopId }).then((res) => {
        if (res) {
          this.dayCards[2] = {
            label1: "环境消毒",
            value1: res.data.status,
            value1: res.data.status,
            label1Color:
              res.data.status == "未进行" || res.data.status == "未消毒"
                ? "#FFAA3B"
                : "",
            label2: "场所清洁",
            value2:
              (res.data.picture + "").indexOf(",") != -1
                ? res.data.picture.split(",")[0]
                : res.data.picture,
            showPop1: false,
            showPop2: false,
            showPop3: false,
          };
        } else {
          this.dayCards[2] = {};
        }
      });

      const scrollData = this.dayCards[4].data;
      await materialDay({ shopId: this.shopDialogParams.shopId }).then(
        (res) => {
          scrollData[0] = {
            label1: "供应商证照公示",
            value1:
              res.data.qualifiedCnt != "--"
                ? res.data.qualifiedCnt + "家合规"
                : res.data.qualifiedCnt,
            label1Color: res.data.qualifiedCnt == "--" ? "#FFAA3B" : "",
            label2: "仓库挡鼠防潮",
            value2:
              res.data.alarmCnt > 0 && res.data.alarmCnt != "--"
                ? "存在风险"
                : res.data.alarmCnt == 0 && res.data.alarmCnt != "--"
                ? "监管正常"
                : res.data.alarmCnt,
            label2Color:
              res.data.alarmCnt > 0 || res.data.alarmCnt == "--"
                ? "#FFAA3B"
                : "",
            label3: "食材农残检测",
            value3: res.data.nongyaoStatus,
            label3Color: res.data.nongyaoStatus != "检测合格" ? "#FFAA3B" : "",
            showPop1: false,
            showPop2: false,
            showPop3: false,
          };
        }
      );
      await materialSafe({ shopId: this.shopDialogParams.shopId }).then(
        (res) => {
          const picture =
            res.data.pictures != "" ? res.data.pictures.split(",") : [];
          const previewImg = picture.map((e) => {
            e = this.imgUrl + e;
            return e;
          });
          let fontColor = "";
          if (
            res.data.outAndInStatus == "未完成" ||
            res.data.outAndInStatus == "未上报"
          ) {
            fontColor = "#FFAA3B";
          } else if (res.data.outAndInStatus == "进行中") {
            fontColor = "#28BB5C";
          } else {
            fontColor = "#14B4FE";
          }
          scrollData[1] = {
            label1: "食堂原料验收状态",
            value1: res.data.outAndInStatus,
            label1Color: fontColor,
            label2: "索证索票",
            value2: picture.length ? picture[0] : "",
            previewImg,
            showPop1: false,
            showPop2: false,
            showPop3: false,
          };
        }
      );
      this.dayCards[4].data = scrollData;
      await workPro({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.dayCards[3] = {
          label1: "食品留样",
          value1: res.data.status,
          label1Color: res.data.status == "未留样" ? "#FFAA3B" : "",
          label2: "留样图片",
          value2:
            (res.data.picture + "").indexOf(",") != -1
              ? res.data.picture.split(",")[0]
              : res.data.picture,
          showPop1: false,
          showPop2: false,
          showPop3: false,
        };
      });
      await hygiene({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.dayCards[5] = {
          label1: "餐饮具消毒",
          value1: res.data.status,
          label1Color: res.data.status == "未处理" ? "#FFAA3B" : "",
          label2: "消毒结果",
          value2:
            (res.data.picture + "").indexOf(",") != -1
              ? res.data.picture.split(",")[0]
              : res.data.picture,
          showPop1: false,
          showPop2: false,
          showPop3: false,
        };
      });
      this.showScroll = true;
    },
    showPop(item, attr, index, dataindex) {
      this.dayCards.forEach((e) => {
        e.showPop1 = false;
        e.showPop2 = false;
        e.showPop3 = false;
      });
      this.noticeDialog = false;
      this.healthShow = false;
      this.popParams.shopId = this.shopId;
      this.popParams.current = 1;
      this.splitWord2 = undefined;
      this.splitWord3 = undefined;
      switch (index) {
        case 0:
          if (dataindex == 1) {
            if (item.value1 === "暂未上传") {
              this.titleContent =
                this.shopDialogParams.shopName + " 经营许可证未上传";
              this.popWord =
                this.shopDialogParams.shopName +
                "还未上传食品经营许可证，请尽快使用“";
              this.splitWord = "智慧门店-门店信息管理-公示";
              this.lastWord = "”功能进行上传";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          } else if (dataindex == 2) {
            if (item.value2 === "暂未公示") {
              this.titleContent =
                this.shopDialogParams.shopName + " 明厨亮灶设备未绑定";
              this.popWord =
                this.shopDialogParams.shopName + "还未绑定监控设备，可前往“";
              this.splitWord = "食安指挥系统-设备管理-监管设备";
              this.lastWord = "”处绑定设备";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          } else {
            if (item.value3 === "暂无菜品") {
              this.titleContent =
                this.shopDialogParams.shopName + " 菜品公示台账未上报";
              this.popWord =
                this.shopDialogParams.shopName + "还未上传菜品信息，可前往“";
              this.splitWord = "智慧食堂-档口菜品管理-菜品管理";
              this.lastWord = "”处上传";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          }
          break;
        case 1:
          if (dataindex == 1) {
            if (item.value1 == "监管风险") {
              this.healthShow = true;
              this.getHealthList();
            }
          } else if (dataindex == 2) {
            if (item.value2 === "--") {
              this.titleContent =
                this.shopDialogParams.shopName + " 晨检台账未上报";
              this.popWord =
                this.shopDialogParams.shopName +
                "今日还未进行晨检台账上传，食堂工作人员应尽快进行晨检工作";
              this.splitWord = "";
              this.lastWord = "";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          } else {
            if (item.value3 == "监管风险") {
              this.getDressingPop();
            }
          }
          break;
        case 2:
          if (dataindex == 1) {
            if (item.value1 === "未进行" || item.value1 == "未消毒") {
              this.titleContent =
                this.shopDialogParams.shopName + " 环境消毒未进行";
              this.popWord =
                this.shopDialogParams.shopName +
                "今日还未完成消毒台账的上传，食堂工作人员应通过“";
              this.splitWord = "智慧食堂-台账管理-食安台账";
              this.lastWord =
                "”上传环境消毒台账完成消毒或开启备餐间消毒设备进行自动上传";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          } else if (dataindex == 2) {
          } else {
          }
          break;
        case 4:
          if (dataindex == 1) {
            if (item.value1 === "--") {
              this.titleContent =
                this.shopDialogParams.shopName + " 供应商证照未上传";
              this.popWord =
                this.shopDialogParams.shopName + "还未上传供应商信息，可前往“";
              this.splitWord = "智慧食堂-门店管理-供应商管理";
              this.lastWord = "”处上传供应商信息";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          } else if (dataindex == 2) {
            if (item.value2 == "存在风险") {
              this.getRatPop();
            }
          } else {
            if (item.value3 == "检测异常") {
              this.nongcanShow = true;
              this.getNongPop();
            }
          }
          break;
        case 3:
          if (dataindex == 1) {
            if (item.value1 === "未留样") {
              this.titleContent =
                this.shopDialogParams.shopName + " 食品留样台账未上报";
              this.popWord =
                this.shopDialogParams.shopName +
                "今日还未进行留样台账记录工作，食堂工作人员应通过“";
              this.splitWord = "留样小程序";
              this.lastWord = "”进行留样，或通过“";
              this.splitWord2 = "智能AI留样秤";
              this.splitWord3 = "”完成今日留样工作";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          } else if (dataindex == 2) {
          } else {
          }
          break;
        case 5:
          if (dataindex == 1) {
            if (item.value1 === "未处理") {
              this.titleContent =
                this.shopDialogParams.shopName + " 餐饮具消毒台账未上报";
              this.popWord =
                this.shopDialogParams.shopName +
                "今日还未完成餐饮具台账的上传，食堂工作人员应通过“";
              this.splitWord = "智慧食堂-台账管理-紫外线消毒台账";
              this.lastWord = "”上传消毒台账完成消毒的记录";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          } else if (dataindex == 2) {
          } else {
          }
          break;
        case 7:
          if (dataindex == 1) {
            if (item.value1 === "--") {
              this.titleContent =
                this.shopDialogParams.shopName + " 燃气探测器离线";
              this.popWord =
                this.shopDialogParams.shopName +
                "燃气探测器设备已全部离线，请尽快排查";
              this.splitWord = "";
              this.lastWord = "";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          } else if (dataindex == 2) {
            if (item.value2 === "--") {
              this.titleContent =
                this.shopDialogParams.shopName + " AI离岗火眼离线";
              this.popWord =
                this.shopDialogParams.shopName +
                "中AI离岗火眼设备已全部离线，请尽快排查";
              this.splitWord = "";
              this.lastWord = "";
              this.$set(item, attr, true);
              this.$set(this.dayCards, index, item);
            }
          } else {
            if (item.value3 == "离岗") {
              this.getSpacePop();
            }
          }
          break;
      }
    },
    getshopGl() {
      this.safeInfo = {
        sceneCnt:0,
      };
      shopGl({ shopId: this.shopDialogParams.shopId }, (e) => {
        cancelToken.push(e);
      }).then((res) => {
        this.safeInfo = res.data || {sceneCnt:0};
        this.$set(this.safeInfo, "sceneCnt", res.data.sceneCnt);
        this.$set(
          this.safeInfo,
          "safeRate",
          res.data.safeRate.replace("%", "")
        );
      });
    },
    getHealthList(item) {
      // if (this.staffManageData.status != "监管风险") {
      //   return;
      // }
      if (item === 1) {
        this.popParams.current = 1;
        this.healthShow = true;
      }
      this.popParams.shopId = this.shopDialogParams.shopId;
      this.healthLoading = true;
      healthPop(this.popParams).then((res) => {
        this.healthLoading = false;
        this.healthList = res.data.records;
        if (this.healthList.length) {
          this.healthList.forEach((e) => (e.fillFlag = false));
          const otherArr = Array(10 - this.healthList.length).fill({
            fillFlag: true,
          });
          this.healthList = this.healthList.concat(otherArr);
        }
        this.total = res.data.total;
      });
    },
    getNongPop(item) {
      this.healthLoading = true;
      if (item === 1) {
        this.popParams.current = 1;
        this.nongcanShow = true;
      }
      this.popParams.shopId = this.shopDialogParams.shopId;
      nongPop(this.popParams).then((res) => {
        this.healthLoading = false;
        this.nongList = res.data.records;
        if (this.nongList.length) {
          this.nongList.forEach((e) => (e.fillFlag = false));
          const otherArr = Array(10 - this.nongList.length).fill({
            fillFlag: true,
          });
          this.nongList = this.nongList.concat(otherArr);
        }
        this.total = res.data.total;
      });
    },
    getDressingPop() {
      // if (this.staffManageData.alarmStatus != "监管风险") {
      //   return;
      // }
      dressingPop({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.noticeList = [];
        this.noticeTitle = this.shopDialogParams.shopName + " 着装不规范报警";
        this.noticeDialog = true;
        res.data.forEach((e) => {
          this.noticeList.push({
            deviceName: e.shopName + "·" + e.deviceName + "“",
            eventName: e.eventName,
            noticeDesc: "”报警事件，请尽快处理",
          });
        });
      });
    },
    getRatPop() {
      ratPop({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.noticeList = [];
        this.noticeTitle =
          this.shopDialogParams.shopName + " 仓库挡鼠板防潮报警";
        this.noticeDialog = true;
        res.data.forEach((e) => {
          if (e.deviceName) {
            this.noticeList.push({
              deviceName: e.shopName + "·" + e.deviceName + "“",
              eventName: e.eventName,
              noticeDesc:
                "”报警中，已持续" + e.continueTime + "分钟，请尽快处理",
            });
          } else {
            this.noticeList.push({
              deviceName: e.shopName + "“",
              eventName: e.eventName,
              noticeDesc:
                "”报警中，已持续" + e.continueTime + "分钟，请尽快处理",
            });
          }
        });
      });
    },
    getSpacePop() {
      if (this.superviseData.status != "离岗") {
        return;
      }
      spacePop({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.noticeList = [];
        this.noticeTitle =
          this.shopDialogParams.shopName + " 烹饪间离人报警详情";
        this.noticeDialog = true;
        res.data.forEach((e) => {
          this.noticeList.push({
            deviceName: e.shopName + "·" + e.deviceName + "“",
            eventName: e.eventName,
            noticeDesc: "”报警中，已持续" + e.continueTime + "分钟，请尽快处理",
          });
        });
      });
    },
    getMorningPie(ref, data, color, tit, timerId) {
      let option = {
        color: [
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [
            {
              offset: 0,
              color: color[0],
            },
            {
              offset: 1,
              color: color[0],
            },
          ]),
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [
            {
              offset: 0,
              color: "#C8D5EB ",
            },
            {
              offset: 1,
              color: "#C8D5EB",
            },
          ]),
        ],
        tooltip: {
          trigger: "item",
          formatter: "{b0}: {c0}",
        },

        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["90%", "100%"],
            label: {
              show: false,
            },
            itemStyle: {
              borderWidth: this.fontSize(1),
              borderColor: "rbg(1,8,24)",
            },

            data: [data[0], data[1]],
          },
          {
            // name: "Access From",
            type: "pie",
            radius: ["70%", "90%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: [`{a|${data[0].value}}`, `{b|${tit}}`].join("\n"),

              rich: {
                a: {
                  color: "#EFEFFF",
                  fontSize: this.fontSize(16),
                  lineHeight: this.fontSize(25),
                  fontWeight: "bold",
                },
                b: {
                  color: "#BCD2ED",
                  fontSize: this.fontSize(12),
                },
              },
            },
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [color[1], " rgba(146, 166, 198, 0.3)"];
                  return colorList[colors.dataIndex];
                },
                borderWidth: this.fontSize(1),
                borderColor: "rbg(1,8,24)",
              },
            },

            labelLine: {
              show: false,
            },
            data: [data[0], data[1]],
          },
        ],
      };
      this.getEcharts(ref, option, 20000, timerId);
    },
    fontSize(res) {
      const clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    // 食堂服务员工
    getmorningCheckInfo() {
      morningCheckInfo({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.morningInfo = res.data;
        this.getMorningPie(
          "znChart",
          [
            {
              name: "已出勤",
              value: res.data.checkCount,
            },
            {
              name: "未出勤",
              value: 0,
            },
          ],
          ["#318EFF", "rgba(84, 155, 241, 0.28)"],
          "晨检人数",
          "2"
        );
        this.getMorningPie(
          "znChart2",
          [
            {
              name: "正常",
              value: res.data.normalCount,
            },
            {
              name: "异常",
              value: res.data.checkCount - res.data.normalCount,
            },
          ],
          ["#4FDC5D", "rgba(60, 187, 73, 0.3)"],
          "体温正常",
          "3"
        );
      });
    },
    getmorningCheckTable() {
      this.morningTable = [];
      morningCheckTable({ shopId: this.shopDialogParams.shopId }).then(
        (res) => {
          for (let i = 0; i < res.data.length; i += 3) {
            this.morningTable.push(res.data.slice(i, i + 3));
          }
        }
      );
    },
    async getZhWlDialogShop() {
      this.mainSwiperListDialog = [];
      this.cards1 = [];
      this.cards2 = [];
      await ygLeace({ sceneId: 13, shopId: this.shopDialogParams.shopId }).then(
        (res) => {
          let alarm = false;
          if (res.operationStatus != "--") {
            const gas = Number(res.setGas);
            let gasConcentration = 0;
            const gasArr = res.gasConcentration.split("%");
            gasConcentration = Number(gasArr[0]);
            if (gasConcentration > gas) {
              alarm = true;
            }
          }
          this.cards1.push({
            label: "后厨油锅燃气监管",
            title1: "油锅温度",
            value1: res.temp != "--" ? res.temp + "℃" : res.temp,
            title2: "监管现场",
            value2: res.status ? res.status : "--",
            title3: "燃气浓度",
            value3:
              res.gasConcentration == "0.0%" ? "0%" : res.gasConcentration,
            deviceNum: res.deviceNum,
            isAlarm:
              ((res.status == "无人" || res.status == "危险") &&
                res.deviceNum != "--" &&
                Number(res.deviceNum.split("/")[0]) > 0) ||
              (res.operationStatus != "--" && alarm)
                ? true
                : false,
            shopName: res.shopName,
            operationStatus: res.operationStatus,
          });
        }
      );
      await sample({ sceneId: 7, shopId: this.shopDialogParams.shopId }).then(
        (res) => {
          this.cards1.push({
            label: "留样监管",
            title1: "留样进度",
            value1: res.mealType,
            title2: "留样温度",
            value2: res.temp != "--" ? res.temp + "℃" : res.temp,
            deviceNum: res.deviceNum,
            isAlarm: res.alarmCnt > 0 ? true : false,
            shopName: res.shopName,
            operationStatus: res.operationStatus,
          });
        }
      );
      await warehouse({
        sceneId: 6,
        shopId: this.shopDialogParams.shopId,
      }).then((res) => {
        this.cards1.push({
          label: "主食仓库",
          title1: "挡鼠门",
          value1: res.ratStatus,
          title2: "防潮光栅",
          value2: res.gratingStatus,
          deviceNum: res.deviceNum,
          temperature:
            res.temperature != "--" && res.temperature
              ? Number(res.temperature).toFixed(0)
              : !res.temperature
              ? "--"
              : res.temperature,
          humidity:
            res.humidity != "--" && res.humidity
              ? Number(res.humidity).toFixed(0)
              : !res.humidity
              ? "--"
              : res.humidity,
          isAlarm:
            ((res.gratingStatus == "危险" || res.ratStatus == "危险") &&
              res.deviceNum != "--" &&
              Number(res.deviceNum.split("/")[0] > 0)) ||
            res.tempHumiAlarm > 0
              ? true
              : false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        });
      });
      await warehouseFu({
        sceneId: 2,
        shopId: this.shopDialogParams.shopId,
      }).then((res) => {
        this.cards1.push({
          label: "副食仓库",
          title1: "挡鼠门",
          value1: res.ratStatus,
          isAlarm:
            (res.ratStatus == "危险" &&
              res.deviceNum != "--" &&
              Number(res.deviceNum.split("/")[0]) > 0) ||
            res.tempHumiAlarm > 0
              ? true
              : false,
          deviceNum: res.deviceNum,
          temperature:
            res.temperature != "--"
              ? Number(res.temperature).toFixed(0)
              : res.temperature,
          humidity:
            res.humidity != "--"
              ? Number(res.humidity).toFixed(0)
              : res.humidity,

          shopName: res.shopName,
          operationStatus: res.operationStatus,
        });
      });
      await clothing({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.cards2.push({
          label: "着装规范",
          title1: "预警",
          value1: res.alarmTotal,
          deviceNum: res.deviceNum,
          isAlarm:
            res.alarmTotal > 0 &&
            res.deviceNum != "--" &&
            Number(res.deviceNum.split("/")[0]) > 0
              ? true
              : false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        });
      });
      await kitchen({ sceneId: 37, shopId: this.shopDialogParams.shopId }).then(
        (res) => {
          this.cards2.push({
            label: "后厨出入监管",
            title1: "晨检",
            value1: res.checkStatus,
            title2: "陌生人",
            value2:
              res.strangerAlarmNum > 0
                ? res.strangerAlarmNum
                : res.strangerStatus,
            deviceNum: res.deviceNum,
            isAlarm:
              res.strangerAlarmNum > 0 &&
              res.deviceNum != "--" &&
              Number(res.deviceNum.split("/")[0]) > 0
                ? true
                : false,
            shopName: res.shopName,
            operationStatus: res.operationStatus,
          });
        }
      );
      await disinfect({
        sceneId: 42,
        shopId: this.shopDialogParams.shopId,
      }).then((res) => {
        this.cards2.push({
          label: "环境消毒",
          title1: "消毒状态",
          value1: res.status,
          title2: res.status == "未消毒" ? "累计时长" : "消毒时长",
          value2:
            res.continueTime != "--"
              ? res.continueTime + "min"
              : res.continueTime,
          deviceNum: res.deviceNum,
          isAlarm: false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        });
      });
      await pesticide({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.cards2.push({
          label: "食材农残检测",
          title1: "食材结果",
          value1: res.status,
          deviceNum: res.deviceNum,
          isAlarm: false,
          shopName: res.shopName,
          operationStatus: res.operationStatus,
        });
      });
      let arrList = this.cards1.concat(this.cards2);

      arrList.sort((a, b) => {
        if (
          a.deviceNum.indexOf("/") != -1 &&
          a.deviceNum.split("/")[1] < b.deviceNum.split("/")[1]
        ) {
          return 1;
        }
        if (
          a.deviceNum.indexOf("/") != -1 &&
          a.deviceNum.split("/")[1] > b.deviceNum.split("/")[1]
        ) {
          return -1;
        }
      });

      // this.mainSwiperListDialog.push({
      //   cards: [arrList[0], arrList[1], arrList[2], arrList[3]],
      //   shopId: this.shopId,
      // });
      // this.mainSwiperListDialog.push({
      //   cards: [arrList[4], arrList[5], arrList[6], arrList[7]],
      //   shopId: this.shopId,
      // });
      const mainSwiperListDialog = [];
      let currentCards = [];

      arrList.forEach(item => {
        if (item.deviceNum.indexOf('/') != -1 && item.deviceNum.split('/')[1] > 0 || (item.operationStatus && item.operationStatus == '手动上传')) {
          currentCards.push(item);
          if (currentCards.length === 4) {
            mainSwiperListDialog.push({
              cards: currentCards,
              shopId: this.shopId,
            });
            currentCards = [];
          }
        }
      });

      // 如果 currentCards 中还有剩余的元素，将其推入 mainSwiperListDialog
      if (currentCards.length > 0&&mainSwiperListDialog.length>0) {
        mainSwiperListDialog.push({
          cards: currentCards,
          shopId: this.shopId,
        });
      }

      // 将生成的 mainSwiperListDialog 赋值给 this.mainSwiperListDialog
      this.mainSwiperListDialog = mainSwiperListDialog;
    },
    getlyRate() {
      lyRate({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.dayRate = res.data || {};
        if (this.dayRate.liuyangMealType.indexOf("1") != -1) {
          this.$set(this.dayRate, "morningMeal", true);
        } else {
          this.$set(this.dayRate, "morningMeal", false);
        }
        if (this.dayRate.liuyangMealType.indexOf("2") != -1) {
          this.$set(this.dayRate, "lunchMeal", true);
        } else {
          this.$set(this.dayRate, "lunchMeal", false);
        }
        if (this.dayRate.liuyangMealType.indexOf("3") != -1) {
          this.$set(this.dayRate, "dinnerMeal", true);
        } else {
          this.$set(this.dayRate, "dinnerMeal", false);
        }
        if (this.dayRate.liuyangMealType.indexOf("4") != -1) {
          this.$set(this.dayRate, "nightMeal", true);
        } else {
          this.$set(this.dayRate, "nightMeal", false);
        }
      });
    },
    previewImg(index) {
      this.sampleDialogData.show = true;
      this.sampleDialogData.index = index;
      this.sampleDialogData.data = this.lyPicList[index];
    },
    sampleChange(type) {
      if (type == 1) {
        if (this.sampleDialogData.index == 0) {
          this.sampleDialogData.index = this.lyPicList.length - 1;
        } else {
          this.sampleDialogData.index -= 1;
        }
      } else {
        if (this.sampleDialogData.index == this.lyPicList.length - 1) {
          this.sampleDialogData.index = 0;
        } else {
          this.sampleDialogData.index += 1;
        }
      }
      this.sampleDialogData.data = this.lyPicList[this.sampleDialogData.index];
    },
    getlyPic() {
      this.lyPicList = [];
      lyPic({ shopId: this.shopDialogParams.shopId }).then((res) => {
        const data = res.data || [];
        this.lyPicList = data.map((e) => {
          e.picture = e.picture ? this.imgUrl + e.picture : "";
          return e;
        });
      });
    },
    getlyRecordList() {
      this.recordList = [];
      lyRecordList({ shopId: this.shopDialogParams.shopId }).then((res) => {
        for (let i = 0; i < res.data.length; i += 3) {
          this.recordList.push(res.data.slice(i, i + 3));
        }
      });
    },
    getshopStaffHealth() {
      shopStaffHealth({ shopId: this.shopDialogParams.shopId }).then((res) => {
        this.shopStaffInfo = res.data || {};

        let option3 = {
          tooltip: {
            trigger: "item",
          },
          color: ["#C8D5EB", "#318eff", "#ffcd58", "#de4d40"],
          series: [
            {
              tooltip: {
                show: false,
                trigger: "item",
              },
              // name: "Access From",
              type: "pie",
              radius: ["90%", "100%"],
              // center: ["50%", "50%"],
              avoidLabelOverlap: false,
              itemStyle: {
                itemStyle: {
                  borderWidth: this.fontSize(1),
                  borderColor: "rbg(1,8,24)",
                },
              },
              // label: {
              //   show: false,
              // },
              label: {
                show: false,
              },

              labelLine: {
                show: false,
              },
              data: [
                res.data.healthNormalCnt,
                res.data.healthAdventCnt,
                res.data.healthExpireCnt,
                res.data.healthHiatusCnt,
              ],
            },
            {
              // name: "Access From",
              type: "pie",
              radius: ["0", "90%"],

              avoidLabelOverlap: false,
              label: {
                formatter: (params) => {
                  return params.percent.toFixed(0) + "%";
                },
                position: "inner",
                show: true,
                fontSize: this.fontSize(9),
                fontWeight: "bold",
                color: "#FFFFFF",
              },
              itemStyle: {
                normal: {
                  borderWidth: this.fontSize(2),
                  borderColor: "rbg(1,8,24)",
                  color: function (colors) {
                    var colorList = [
                      "rgba(161, 174, 195, 0.28)",
                      "rgba(84, 155, 241, 0.28)",
                      "rgba(255, 205, 88, 0.23)",
                      " rgba(199,100,86,0.28)",
                    ];
                    return colorList[colors.dataIndex];
                  },
                },
              },

              labelLine: {
                show: false,
              },

              data: [
                {
                  name: "正常",
                  value: res.data.healthNormalCnt,
                },
                {
                  name: "临期",
                  value: res.data.healthAdventCnt,
                },
                {
                  name: "过期",
                  value: res.data.healthExpireCnt,
                },
                {
                  name: "缺失",
                  value: res.data.healthHiatusCnt,
                },
              ],
            },
          ],
        };
        this.getEcharts("znChart3", option3, 20000, "1");
      });
    },
    getEcharts(ref, options, time, timerId) {
      let chart = this.$refs[ref];
      let myChart;
      if (chart) {
        myChart = echarts.init(chart);
        options && myChart.setOption(options);
      }
    },
    // 分数
    getFoodSafetyIndexDataOnShopDialog() {
      shopScoreList().then((res) => {
        // this.shopIndexData = res;
        this.safeScoreShopList = JSON.parse(JSON.stringify(res.data));
        //前端将食堂排序
        let shopItem = this.safeScoreShopList.splice(5, 1);
        this.safeScoreShopList.splice(3, 0, shopItem[0]);
        this.safeScoreShopList.forEach((data, index) => {
          data.isChoose = false;
          if (index == 0) {
            data.isChoose = true;
          }
          data.color =
            data.score >= 90
              ? "#318EFF"
              : data.score >= 70
              ? "#FFAA3B"
              : "#FF6B43";
          if (this.safeScoreItem) {
            data.isChoose =
              data.shopId == this.safeScoreItem.shopId ? true : false;
          }
        });
        this.shopScoreParams.shopId = this.safeScoreShopList[0].shopId;
        this.shopScoreParams.shopName = this.safeScoreShopList[0].shopName;
        this.shopScoreParams.current = 1;
        this.shopScoreParams.grade = 1;
        this.safeScoreItem = this.safeScoreShopList[0];
        this.scoreDetailShopClick(this.safeScoreItem, 0);
      });
    },
    // 食安分数详情左侧点击
    scoreDetailShopClick(item, index) {
      this.safeScoreShopList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.safeScoreShopList, index, item);
      this.shopScoreParams.shopId = item.shopId;
      this.shopScoreParams.shopName = item.shopName;
      this.shopScoreParams.current = 1;
      this.shopScoreParams.grade = 1;
      this.safeScoreItem = item;
      this.getShopScoreList();
      this.getScoreTop();
    },
    getShopScoreList() {
      this.scoreLoading = true;
      this.safeScoreList = [];
      shopScoreDetailPage(this.shopScoreParams).then((res) => {
        this.scoreLoading = false;
        this.safeScoreList = res.data.records;
        this.delScoreList = [];
        if (this.safeScoreList.length > 0) {
          this.safeScoreList.forEach((item) => {
            item.isChoose = false;
          });
          this.safeScoreList[0].isChoose = true;
          this.currentEventName = this.safeScoreList[0].eventName;
          this.scoreRightLoading = true;
          this.getDelScore(this.safeScoreList[0].shopSceneId);
          const nodataList = Array(10 - this.safeScoreList.length).fill({
            fillFlag: true,
          });
          this.safeScoreList = this.safeScoreList.concat(nodataList);
        }

        this.total = res.data.total;
      });
    },
    // 扣分详情
    showDelScore(e, index) {
      if (e.fillFlag) {
        return;
      }
      this.delScoreList = [];
      this.currentEventName = e.eventName || "";
      if (!e.fillFlag) {
        this.safeScoreList.forEach((item) => {
          item.isChoose = false;
        });
        e.isChoose = true;
        this.$set(this.safeScoreList, index, e);
      } else {
        this.safeScoreList.forEach((item) => {
          item.isChoose = false;
        });
      }
      this.scoreRightLoading = true;

      this.getDelScore(e.shopSceneId);
    },
    getDelScore(shopSceneId = "") {
      if (!this.currentEventName) {
        this.scoreRightLoading = false;
        this.delScoreList = [];
        return;
      }
      const conplaintType = [
        "",
        {
          label: "身体不适",
          value: 1,
        },
        {
          label: "就餐卫生",
          value: 2,
        },
        {
          label: "菜品卫生",
          value: 3,
        },
        {
          label: "菜品口味",
          value: 4,
        },
        {
          label: "服务态度",
          value: 5,
        },
        {
          label: "其他",
          value: 6,
        },
      ];
      let query = null;
      const params = {
        shopId: this.shopScoreParams.shopId,
        shopSceneId,
      };
      if (this.currentEventName == "客户投诉") {
        query = complaintAlarmList;
        params.type = 1;
      } else if (this.currentEventName == "客户建议") {
        query = complaintAlarmList;
        params.type = 2;
      } else if (this.currentEventName == "健康证更新提醒") {
        query = healthUpdateAlarmList;
      } else if (this.currentEventName == "员工人脸信息待完善") {
        query = faceAlarmList;
      } else if (
        this.currentEventName.indexOf("严重警告") != -1 ||
        this.currentEventName.indexOf("食堂预警") != -1 ||
        this.currentEventName.indexOf("预防提醒") != -1
      ) {
        query = sceneAlarmList;
        params.type = this.shopScoreParams.grade;
      } else if (this.currentEventName.indexOf("门店经营许可证") != -1) {
        query = licenseAlarmList;
      } else if (this.currentEventName == "供货商营业执照缺失/过期风险") {
        query = supplierAlarmList;
        params.type = 2;
      } else if (this.currentEventName == "供货商营业执照临期风险") {
        query = supplierAdventAlarmList;
        params.type = 2;
      } else if (this.currentEventName == "供货商经营许可证缺失/过期风险") {
        query = supplierAlarmList;
        params.type = 1;
      } else if (this.currentEventName == "供货商经营许可证临期风险") {
        query = supplierAdventAlarmList;
        params.type = 1;
      } else if (this.currentEventName == "陌生人报警") {
        query = strangerAlarmList;
      } else if (this.currentEventName == "销样提醒") {
        query = sampleAlarmList;
      } else if (this.currentEventName == "食堂待整改") {
        query = rectifyAlarmList;
      } else if (this.currentEventName == "后厨人员温度异常") {
        query = accessAlarmList;
      } else if (this.currentEventName == "AI行为识别不合规") {
        query = aiAlarmList;
      } else if (this.currentEventName == "离人报警") {
        query = leaveAlarmList;
      } else if (this.currentEventName == "烹饪间灶台温度过高") {
        query = temperatureAlarmList;
      } else if (this.currentEventName == "健康证风险") {
        query = healthAlarmList;
      } else if (this.currentEventName == "自检提醒") {
        query = selfAlarmList;
      }
      this.delScoreList = [];
      query(params).then((res) => {
        const data = res.data;
        this.scoreRightLoading = false;
        if (data instanceof Array) {
          this.delScoreList = data;
        } else if (data instanceof Object) {
          this.delScoreList = [data];
        } else {
          this.delScoreList = null;
        }
        if (this.delScoreList) {
          if (
            this.currentEventName == "客户投诉" ||
            this.currentEventName == "客户建议"
          ) {
            this.delScoreList.forEach((e) => {
              const type = e.complaintType.split(",");
              const typeLabel = [];
              type.forEach((ee) => {
                typeLabel.push(conplaintType[Number(ee)].label);
              });
              e.typeLabel = typeLabel;
            });
          }
          if (this.currentEventName.indexOf("门店经营许可证") != -1) {
            this.delScoreList.forEach((e) => {
              const day = e.day;
              let status = 1; // 0缺失1到期  2 临期  3 正常
              if (day == null) {
                status = 0;
              } else if (day <= 20) {
                status = 1;
              } else if (day > 20 && day < 60) {
                status = 2;
              } else if (day >= 60 && day <= 90) {
                status = 3;
              }
              e.status = status;
              e.overDate = e.licenseValidityEndTime;
            });
          }
          if (this.currentEventName.indexOf("供货商营业执照") != -1) {
            this.delScoreList.forEach((e) => {
              let licenseStatus = e.licenseStatus;
              let status = 1; // 0缺失1到期  2 临期  3 正常
              if (licenseStatus == "到期") {
                status = 1;
              } else if (licenseStatus == "缺失") {
                status = 0;
              } else if (licenseStatus == "临期") {
                status = 2;
              }
              e.status = status;
              e.overDate = e.businessLicenseEndTime;
            });
          }
          if (this.currentEventName.indexOf("供货商经营许可证") != -1) {
            this.delScoreList.forEach((e) => {
              let permitStatus = e.permitStatus;
              let status = 1; // 0缺失1到期  2 临期  3 正常
              if (permitStatus == "到期") {
                status = 1;
              } else if (permitStatus == "缺失") {
                status = 0;
              } else if (permitStatus == "临期") {
                status = 2;
              }
              e.status = status;
              e.overDate = e.foodLicenseEndTime;
            });
          }
        } else {
          this.delScoreList = [
            {
              status: 0,
            },
          ];
        }
      });
    },
    chooseScoreIt(item, index) {
      this.scoreChooseList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.scoreChooseList, index, item);
      if (item.label == "严重风险") {
        this.shopScoreParams.grade = 1;
      } else if (item.label == "食堂预警") {
        this.shopScoreParams.grade = 2;
      } else {
        this.shopScoreParams.grade = 3;
      }
      this.shopScoreParams.current = 1;
      this.getShopScoreList();
    },
    getScoreTop() {
      shopScoreDetail(this.shopScoreParams).then((res) => {
        this.scoreChooseList = [
          {
            label: "严重风险",
            isChoose: true,
            score: res.data.serious,
          },
          {
            label: "食堂预警",

            isChoose: false,
            score: res.data.warning,
          },
          {
            label: "注意预防",

            isChoose: false,
            score: res.data.remain,
          },
        ];
        this.shopScoreParams.grade = 1;
      });
    },
    // --------
    getShopList() {
      shopLi().then((res) => {
        this.shopList = res || [];
        //前端将食堂排序
        let shopItem = this.shopList.splice(5, 1);
        this.shopList.splice(3, 0, shopItem[0]);
        this.shopId = this.shopList[0].shopId;
      });
    },
    getDeviceBf() {
      this.sceneList = [];
      deviceBf().then((res) => {
        // let arr = [...res, ...res];
        // let newList = [];
        for (let i = 0; i < res.length; i += 3) {
          this.sceneList.push(res.slice(i, i + 3));
        }
      });
    },
    tabClick(shopId) {
      this.nextShopId = this.shopId;
      this.shopId = shopId;
      this.showPoint = false;
      setTimeout(() => {
        this.showPoint = true;
      });
    },
    // 智慧设备分析
    getWisdomDeviceData() {
      znDevice().then((res) => {
        this.wisdomData = res;
        if (this.wisdomDeviceTimer) {
          clearInterval(this.wisdomDeviceTimer);
          this.wisdomDeviceTimer = null;
        }
        const seriesData = this.dealProgressPieData(
          Number(res.onlineRate),
          Number(res.unlineRate)
        );
        this.$nextTick(() => {
          this.$refs.devicePie.initChart(
            seriesData,
            res.deviceTotal,
            "设备总数"
          );
          this.wisdomDeviceTimer = setInterval(() => {
            this.$refs.devicePie.initChart(
              seriesData,
              res.deviceTotal,
              "设备总数"
            );
          }, 60000);
        });
      });
    },
    // 智慧食堂物联监管
    getWisdomMonit() {
      Promise.all([
        ygLeace({ shopId: this.shopId, sceneId: 13 }),
        sample({ shopId: this.shopId, sceneId: 7 }),
        warehouse({ shopId: this.shopId, sceneId: 6 }),
        warehouse({ shopId: this.shopId, sceneId: 2 }),
        clothing({ shopId: this.shopId }),
        kitchen({ shopId: this.shopId, sceneId: 37 }),
        disinfect({ shopId: this.shopId, sceneId: 42 }),
        pesticide({ shopId: this.shopId }),
      ]).then((res) => {
        const data = res;
        let wisdomMonitData = [];
        data.forEach((e, index) => {
          e.deviceLabel = "布防设备";
          e.index = index;
          const deviceNum =
            e.deviceNum == "--" || !e.deviceNum ? 0 : e.deviceNum.split("/")[0];
          const total =
            e.deviceNum == "--" || !e.deviceNum ? 0 : e.deviceNum.split("/")[1];
          e.total = total;
          switch (index) {
            case 0:
              let alarm = false;
              if (e.operationStatus != "--") {
                const gas = Number(e.setGas);
                let gasConcentration = 0;
                const gasArr = e.gasConcentration.split("%");
                gasConcentration = Number(gasArr[0]);
                if (gasConcentration > gas) {
                  alarm = true;
                }
              }
              e.sceneName = "后厨油锅燃气监管";
              e.statusLabel = "油锅温度";
              e.statusLabelTwo = "监管现场";
              e.statusLabelThree = "燃气浓度";
              e.value1 = e.temp != "--" ? e.temp + "℃" : e.temp;
              e.value2 = e.status ? e.status : "--";
              e.value3 =
                e.gasConcentration == "0.0%" ? "0%" : e.gasConcentration;
              e.isAlarm =
                ((e.status == "无人" || e.status == "危险") &&
                  deviceNum != 0) ||
                (e.operationStatus != "--" && alarm)
                  ? true
                  : false;
              break;
            case 1:
              e.sceneName = "留样监管";
              e.statusLabel = "留样进度";
              e.statusLabelTwo = "留样温度";
              (e.value1 = e.mealType),
                (e.value2 = e.temp != "--" ? e.temp + "℃" : e.temp),
                (e.isAlarm = e.alarmCnt > 0 ? true : false);
              break;
            case 2:
              e.sceneName = "主食仓库";
              e.statusLabel = "挡鼠门";
              e.statusLabelTwo = "防潮光栅";
              e.value1 = e.ratStatus;
              e.value2 = e.gratingStatus;
              e.isAlarm =
                (deviceNum != 0 &&
                  (e.gratingStatus == "危险" || e.ratStatus == "危险")) ||
                e.tempHumiAlarm > 0
                  ? true
                  : false;
              break;
            case 3:
              e.sceneName = "副食仓库";
              e.statusLabel = "挡鼠门";
              // e.statusLabelTwo = '防潮光栅';
              e.value1 = e.ratStatus;
              // e.value2 = e.gratingStatus,
              e.isAlarm =
                (e.ratStatus == "危险" && deviceNum != 0) || e.tempHumiAlarm > 0
                  ? true
                  : false;
              break;
            case 4:
              e.sceneName = "着装规范";
              e.statusLabel = "预警";
              (e.value1 = e.alarmTotal),
                (e.isAlarm = e.alarmTotal > 0 && deviceNum != 0 ? true : false);
              break;
            case 5:
              (e.sceneName = "后厨出入监管"), (e.statusLabel = "晨检");
              e.statusLabelTwo = "陌生人";
              (e.value1 = e.checkStatus),
                (e.value2 = e.strangerStatus),
                (e.isAlarm =
                  e.strangerAlarmNum > 0 && deviceNum != 0 ? true : false);
              break;
            case 6:
              (e.sceneName = "环境消毒"), (e.statusLabel = "消毒状态");
              e.statusLabelTwo = "消毒时长";
              (e.value1 = e.status),
                (e.value2 =
                  e.continueTime != "--"
                    ? e.continueTime + "min"
                    : e.continueTime);
              e.isAlarm = false;
              break;
            case 7:
              (e.sceneName = "食材农残检测"), (e.statusLabel = "食材结果");
              e.isAlarm = false;
              e.value1 = e.status;
              break;
          }
          wisdomMonitData.push(e);
        });
        this.wisdomMonitData = wisdomMonitData;
        let cardNum = this.wisdomMonitData.reduce((num, item) => {
          return (
            num +
            (item.total != 0 || item.operationStatus === "手动上传" ? 1 : 0)
          );
        }, 0);
        this.cardNum = cardNum;
      });
    },
    mousemove2(e) {
      if (this.scrollIng) {
        return;
      }
      const notices = this.$refs.tabWrap;
      const clientWidth = this.$refs.wrap.clientWidth;
      const x = clientWidth - e.x;
      const elWidth = e.target.offsetWidth;
      const margin = 8 * this.widthPix;
      const parentWidth = e.target.offsetParent.offsetWidth;
      let tabLeft = 0;
      let isSame = false;
      // 右侧
      if (x < parentWidth / 2 + 40 * this.widthPix) {
        tabLeft = elWidth * 4 + margin * 4;
      } else {
        tabLeft = 0;
      }
      // 重复移动同一个位置
      if (this.tabLeft == tabLeft) {
        isSame = true;
      } else {
        this.tabLeft = tabLeft;
      }

      // let isScroll = false;
      // if(this.nextTabLeft != this.tabLeft){
      //   isScroll = true
      // }
      if (!isSame) {
        this.scrollIng = setTimeout(() => {
          clearInterval(this.scrollIng);
          this.scrollIng = null;
        }, 4000);
      }
    },
    // 数字巡检
    getInspectionAnalyseData() {
      inspectionAnalyse().then((res) => {
        const data = res;
        if (this.digitalTimer) {
          clearInterval(this.digitalTimer);
          this.digitalTimer = null;
        }
        const category = data.categories;
        // const onLineScore = data.map((e) => Number(e.onlineScore));
        // const offLineScore = data.map((e) => Number(e.offlineScore));
        const series = [
          {
            name: "督导巡检",
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(43, 62, 81, 0.6)",
            },
            stack: "Search Engine",
            barWidth: 10 * this.widthPix,

            itemStyle: {
              color: "#47B5FF",

              borderColor: "#000",
              borderWidth: 1 * this.widthPix,
            },
            data: data.series[0].data,
          },
          {
            name: "视频巡检",
            type: "bar",
            showBackground: true,
            stack: "Search Engine",
            backgroundStyle: {
              color: "rgba(43, 62, 81, 0.6)",
            },
            barWidth: 10 * this.widthPix,
            itemStyle: {
              color: "#FF9956",

              borderColor: "#000",
              borderWidth: 1 * this.widthPix,
            },
            data: data.series[1].data,
          },
          {
            name: "自检自清",
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(43, 62, 81, 0.6)",
            },

            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                color: "#fff",
                fontSize: 12 * this.widthPix,
              },
              formatter: function (params) {
                let num =
                  data.series[0].data[params.dataIndex] +
                  data.series[1].data[params.dataIndex] +
                  data.series[2].data[params.dataIndex];
                return num;
              },
            },
            stack: "Search Engine",
            barWidth: 10 * this.widthPix,

            itemStyle: {
              color: "#D0DEEE",

              borderColor: "#000",
              borderWidth: 1 * this.widthPix,
            },

            data: data.series[2].data,
          },
        ];
        this.$refs.scoreLine.initChart(series, category);
        this.digitalTimer = setInterval(() => {
          this.$refs.scoreLine.initChart(series, category);
        }, 60000);
      });
    },
    // 食安指数数据
    getFoodSafetyIndexData() {
      foodSafetyIndex().then((res) => {
        this.shopIndexData = res;
        res.forEach((e, index) => {
          e.index = index;
        });
        if (this.shopIndexTimer) {
          clearInterval(this.shopIndexTimer);
          this.shopIndexTimer = null;
        }
        this.getKeySafePieList("pie1", res[0]);
        this.getKeySafePieList("pie2", res[1]);
        this.getKeySafePieList("pie3", res[2]);
        this.getKeySafePieList("pie4", res[3]);
        this.getKeySafePieList("pie5", res[4]);
        this.getKeySafePieList("pie6", res[5]);

        this.shopIndexTimer = setInterval(() => {
          this.getKeySafePieList("pie1", res[0]);
          this.getKeySafePieList("pie2", res[1]);
          this.getKeySafePieList("pie3", res[2]);
          this.getKeySafePieList("pie4", res[3]);
          this.getKeySafePieList("pie5", res[4]);
          this.getKeySafePieList("pie6", res[5]);
        }, 60000);
      });
    },
    dealProgressPieData(complete, inComplete) {
      return [
        {
          type: "pie",
          radius: ["85%", "95%"],
          // center: ["50%", "50%"],
          color: ["#01A7FA", "#D85555"],
          label: {
            show: false,
          },
          itemStyle: {
            borderColor: "#000",
            borderWidth: 3 * this.widthPix,
          },
          data: [
            { value: complete, name: "在线" },
            { value: inComplete, name: "离线" },
          ],
        },
        {
          type: "pie",
          radius: ["60%", "85%"],
          center: ["50%", "50%"],
          label: {
            show: false,
          },
          itemStyle: {
            borderColor: "#000",
            borderWidth: 3 * this.widthPix,
          },
          data: [
            {
              value: complete,
              name: "在线",
              itemStyle: {
                color: " rgba(1, 167, 250, 0.3)",
              },
            },
            {
              value: inComplete,
              name: "离线",
              itemStyle: {
                color: "rgba(216,85,85, 0.3)",
              },
            },
          ],
        },
      ];
    },
    getKeySafePieList(ref, data) {
      const color =
        data.score < 0
          ? ["#D85555", "#FFA66F"]
          : data.score >= 90
          ? ["#3FB5FF", "#3FB5FF"]
          : data.score >= 70
          ? ["#FFEB93", "#FF8C34"]
          : ["#D85555", "#FFA66F"];
      const score = data.score;

      let img =
        "https://dobaycdn.anasit.com/cqgs_dashboard_images/circle-bac.png"; //加载文件
      let option = {
        graphic: {
          // 这个属性可以在饼图内部填充图片,文字等
          elements: [
            {
              type: "image",
              style: {
                image: img, //这里添加图片地址
                width: 50 * this.widthPix,
                height: 50 * this.widthPix,
              },
              left: "center", //
              top: "middle", //配置图片居中
            },
          ],
        },

        title: {
          text: data.isActive == 0 ? "未启用" : score,
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: data.isActive == 0 ? "#BCD2ED" : "#fff",
            fontFamily: "DINCondensed-Bold-Bold",
            fontSize:
              data.isActive == 0 ? 9 * this.widthPix : 20 * this.widthPix,
          },
        },
        color: ["rgba(67, 96, 122, 0.6)"],

        series: [
          {
            name: "Line 1",
            type: "pie",
            clockWise: true,
            radius: ["86%", "100%"],
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              scale: false,
            },
            // hoverAnimation: false,
            data: [
              {
                value: score < 0 ? 100 : score,
                name: "01",
                itemStyle: {
                  color: {
                    // 完成的圆环的颜色
                    colorStops: [
                      {
                        offset: 0,
                        color: color[0], // 0% 处的颜色
                      },
                      {
                        offset: 0.5,
                        color: color[0], // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: color[1], // 100% 处的颜色
                      },
                    ],
                  },
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                },
              },
              {
                name: "02",
                value: score < 0 ? 0 : 100 - score,
              },
            ],
          },
        ],
      };
      let chart = this.$refs[ref];
      let myChart;
      if (chart) {
        if (myChart != null && myChart != "" && myChart != undefined) {
          myChart.dispose();
        }
        myChart = echarts.init(chart);
        myChart.clear();
        option && myChart.setOption(option);
      }
    },
    // 食堂概览
    getShopStatisticsData() {
      shopStatistics().then((res) => {
        if (this.flipperTimer) {
          clearInterval(this.flipperTimer);
          this.flipperTimer = null;
        }
        this.shopStatisticsData = res;
        this.showFilpper = true;
        this.flipperTimer = setInterval(() => {
          this.showFilpper = false;
          setTimeout(() => {
            this.showFilpper = true;
          });
        }, 60000);
      });
    },
    getSopData() {},
    stopAlarm(item = null) {
      const params = {
        alarmPlatformId:
          item != null
            ? item.alarmPlatformId
            : this.alarmNotice.alarmPlatformId,
        eventTime: item != null ? item.eventTime : this.alarmNotice.eventTime,
      };
      closeAlarmAll([params]).then((res) => {
        if (res.code === 200) {
          if (item != null) {
            if (item.alarmPlatformId == this.alarmNotice.alarmPlatformId) {
              this.$refs.audio.pause();
              this.alarmNotice = undefined;
              const nowIndex = this.alarmNoticeList.findIndex(
                (e) => e.alarmPlatformId == item.alarmPlatformId
              );
              item.isStop = 1;
              this.$set(this.alarmNoticeList, nowIndex, item);
              const alarmIndex = this.alarmNoticeList.findIndex(
                (e) => e.isStop != 1
              );
              if (alarmIndex != -1) {
                this.alarmNotice = {
                  ...this.alarmNoticeList[alarmIndex],
                };

                if (
                  (localStorage.getItem("voiceChecked") == null ||
                    localStorage.getItem("voiceChecked") == "true") &&
                  (localStorage.getItem("alarmChecked") == null ||
                    localStorage.getItem("alarmChecked") == "true")
                ) {
                  // 播放报警
                  this.$refs.audio.play();
                } else {
                  // 停止报警语音
                  this.$refs.audio.pause();
                }
              }
            } else {
              item.isStop = 1;
              const nowIndex = this.alarmNoticeList.findIndex(
                (e) => e.alarmPlatformId == item.alarmPlatformId
              );
              this.$set(this.alarmNoticeList, nowIndex, item);
            }
          } else {
            this.$refs.audio.pause();
            const nowIndex = this.alarmNoticeList.findIndex(
              (e) => e.alarmPlatformId == this.alarmNotice.alarmPlatformId
            );
            if (nowIndex) {
              let alarmItem = this.alarmNoticeList[nowIndex];
              alarmItem.isStop = 1;
              this.$set(this.alarmNoticeList, nowIndex, alarmItem);
            }
            this.alarmNotice = undefined;
            const alarmIndex = this.alarmNoticeList.findIndex(
              (e) => e.isStop != 1
            );
            if (alarmIndex != -1) {
              this.alarmNotice = {
                ...this.alarmNoticeList[alarmIndex],
              };
              if (
                (localStorage.getItem("voiceChecked") == null ||
                  localStorage.getItem("voiceChecked") == "true") &&
                (localStorage.getItem("alarmChecked") == null ||
                  localStorage.getItem("alarmChecked") == "true")
              ) {
                // 播放报警
                this.$refs.audio.play();
              } else {
                // 停止报警语音
                this.$refs.audio.pause();
              }
            }
          }
        }
      });
    },
    stopAlarmAll() {
      closeAlarmAll(this.alarmNoticeList).then((res) => {
        if (res.code === 200) {
          this.alarmNotice = undefined;
          this.$refs.audio.pause();
          this.alarmNoticeList.forEach((e) => {
            e.isStop = 1;
          });
        }
      });
    },
    // 跑马灯
    getNoticeData() {
      this.noticeDataNew = [];
      this.scrollDelay = 2;
      notice().then((res) => {
        if (res.length == 0) {
          this.hasNotice = false;
        } else {
          this.hasNotice = true;
        }
        if (
          this.noticeData.length == 0 ||
          (this.noticeData.length == 1 &&
            this.noticeData.includes(
              "指挥大屏保障您的食品安全，请您放心用餐！"
            ))
        ) {
          this.noticeData = JSON.parse(JSON.stringify(res));
          this.noticeDataOri = JSON.parse(JSON.stringify(res));
        } else {
          this.noticeDataNew = JSON.parse(JSON.stringify(res));
        }
        this.$nextTick(() => {
          if (this.hasNotice) {
            if (!this.scrollScrollTimer) {
              const noticeWrap = this.$refs["notice-scroll"];
              const itemWidth = noticeWrap.offsetWidth; //428
              this.scrollScrollTimer = setInterval(() => {
                this.scrollLeft -= itemWidth;
                if (this.noticeData.length > 1) {
                  if (
                    -this.scrollLeft / itemWidth ==
                    this.noticeData.length - 1
                  ) {
                    // 有新获取数据   添加新获取的数据
                    if (this.noticeDataNew.length) {
                      this.noticeData.push(...this.noticeDataNew);
                    } else {
                      this.noticeData.push(...this.noticeDataOri);
                    }
                  }
                } else {
                  if (this.noticeDataNew.length) {
                    this.noticeData.push(...this.noticeDataNew);
                  } else {
                    this.noticeData.push(...this.noticeDataOri);
                  }
                }

                if (-this.scrollLeft / itemWidth == this.noticeDataOri.length) {
                  setTimeout(() => {
                    this.scrollDelay = 0;
                    this.scrollLeft = 0;
                    setTimeout(() => {
                      this.scrollDelay = 2;
                    }, 200);
                    // 是否有数据
                    if (this.hasNotice) {
                      // 有新数据。当前截取原始数据。并将新数据复制原始数据
                      if (this.noticeDataNew.length) {
                        this.noticeData.splice(0, this.noticeDataOri.length);
                        this.noticeDataOri = JSON.parse(
                          JSON.stringify(this.noticeDataNew)
                        );
                        this.noticeDataNew = [];
                      } else {
                        this.noticeData.splice(0, this.noticeDataOri.length);
                      }
                    } else {
                      this.noticeData = [
                        "指挥大屏保障您的食品安全，请您放心用餐！",
                      ];
                      this.noticeDataOri = [];
                      this.noticeDataNew = [];
                      clearInterval(this.scrollScrollTimer);
                      this.scrollScrollTimer = null;
                    }
                  }, 2000);
                }
              }, 7000);
            }
          } else {
            if (!this.scrollScrollTimer) {
              this.noticeData = ["指挥大屏保障您的食品安全，请您放心用餐！"];
              this.noticeDataOri = [];
              this.noticeDataNew = [];
            }
          }
        });
      });
    },
    resetScrollNotice() {
      this.scrollLeft = 0;
      const startTime = this.noticeCnt == 0 ? 200 : 1500;
      this.scrollDelay = startTime == 200 ? 0 : 1;
      this.noticeCnt++;
      this.scrollTimer = setTimeout(() => {
        const scrollWrap = document.querySelector(".scroll-wrap");
        this.noticeScrollWidth = scrollWrap.scrollWidth;
        this.noticeClientWidth = scrollWrap.clientWidth;
        this.scrollLeft = this.noticeScrollWidth - this.noticeClientWidth;
        this.scrollDelay = Math.ceil(this.scrollLeft / 50);
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.scrollScrollTimer = setTimeout(() => {
          // 执行完毕  状态都清空
          this.resetScrollNotice();
          clearTimeout(this.scrollScrollTimer);
          this.scrollScrollTimer = null;
        }, this.scrollDelay * 1000 + 1000);
      }, startTime);
    },
    // 实时报警
    getRealAlarmData() {
      let shopId = "";
      if (
        localStorage.getItem("alarmChecked") != null &&
        localStorage.getItem("alarmChecked") == "false"
      ) {
        shopId = this.shopId;
      }
      realAlarm(shopId).then((res) => {
        if (
          localStorage.getItem("alarmChecked") != null &&
          localStorage.getItem("alarmChecked") == "false"
        ) {
          return;
        }
        const ids = [];
        const noStopData = [];
        res.data.forEach((e) => {
          ids.push(e.shopId);
          if (e.isStop != 1) {
            noStopData.push(e);
          }
          const arr = e.capturePicture ? e.capturePicture.split(",") : [];
          e.capturePicture = e.capturePicture ? arr[0] : e.capturePicture;
        });
        this.alarmNoticeList = res.data;
        console.log("alarmnotice", this.alarmNotice);
        console.log(
          localStorage.getItem("alarmChecked"),
          'localStorage.getItem("alarmChecked")'
        );
        if (this.alarmNotice) {
          if (res.data.length) {
            // 最新报警还是当前正在报警的数据。继续报警下去。不做操作
            const noStopData = res.data.filter((e) => e.isStop != 1);
            if (
              noStopData.length &&
              this.alarmNotice.alarmPlatformId === noStopData[0].alarmPlatformId
            ) {
            } else {
              if (noStopData.length) {
                this.stopCurrentAlarm(() => {
                  this.alarmNotice = {
                    ...noStopData[0],
                  };
                  if (
                    localStorage.getItem("alarmChecked") == null ||
                    localStorage.getItem("alarmChecked") == "true"
                  ) {
                    clearTimeout(this.noticeTimer);
                    this.noticeTimer = null;

                    this.showTenSecondWarn();
                  } else {
                    this.alarmNotice = undefined;
                    clearTimeout(this.noticeTimer);
                    this.noticeTimer = null;
                  }
                });
              } else {
                this.alarmNotice = undefined;
                clearTimeout(this.noticeTimer);
                this.noticeTimer = null;
              }
            }
          }
        } else {
          const alarm = res.data.findIndex((e) => e.isStop != 1);
          if (alarm != -1) {
            this.alarmNotice = {
              ...res.data[alarm],
            };
            if (
              localStorage.getItem("alarmChecked") == null ||
              localStorage.getItem("alarmChecked") == "true"
            ) {
              this.showTenSecondWarn();
            } else {
              this.alarmNotice = undefined;
              clearTimeout(this.noticeTimer);
              this.noticeTimer = null;
            }
          } else {
            this.alarmNotice = undefined;
            clearTimeout(this.noticeTimer);
            this.noticeTimer = null;
          }
        }
        this.pointList.forEach((point) => {
          if (ids.includes(point.shopId)) {
            if (point.isAlarm) {
              point.again = true;
            }
            point.isAlarm = true;
          } else {
            point.isAlarm = false;
            point.again = false;
          }
        });
        if (noStopData.length) {
          if (
            (localStorage.getItem("voiceChecked") == null ||
              localStorage.getItem("voiceChecked") == "true") &&
            (localStorage.getItem("alarmChecked") == null ||
              localStorage.getItem("alarmChecked") == "true")
          ) {
            // 播放报警
            this.$refs.audio.play();
          } else {
            // 停止报警语音
            this.$refs.audio.pause();
          }
        } else {
          // 停止报警语音
          this.$refs.audio.pause();
          this.pointList.forEach((point) => {
            point.again = false;
            point.isAlarm = false;
          });
          this.alarmNotice = undefined;
          clearTimeout(this.noticeTimer);
          this.noticeTimer = null;
        }
        if (this.warnNoticeTimer != null) {
          return;
        }

        this.warnNoticeTimer = setInterval(() => {
          let shopId = "";
          if (
            localStorage.getItem("alarmChecked") != null &&
            localStorage.getItem("alarmChecked") == "false"
          ) {
            shopId = this.shopId;
          }
          realAlarm(shopId).then((res) => {
            if (
              localStorage.getItem("alarmChecked") != null &&
              localStorage.getItem("alarmChecked") == "false"
            ) {
              return;
            }
            res.data.forEach((e) => {
              const arr = e.capturePicture ? e.capturePicture.split(",") : [];
              e.capturePicture = e.capturePicture ? arr[0] : e.capturePicture;
            });
            this.alarmNoticeList = res.data;
            console.log(this.alarmNotice, "this.alarmNotice--timer");

            if (this.alarmNotice) {
              if (res.data.length) {
                const noStopData = res.data.filter((e) => e.isStop != 1);
                // 最新报警还是当前正在报警的数据。继续报警下去。不做操作
                if (
                  noStopData.length &&
                  this.alarmNotice.alarmPlatformId ===
                    noStopData[0].alarmPlatformId
                ) {
                } else {
                  if (noStopData.length) {
                    this.stopCurrentAlarm(() => {
                      this.alarmNotice = {
                        ...noStopData[0],
                      };
                      if (
                        localStorage.getItem("alarmChecked") == null &&
                        localStorage.getItem("alarmChecked") == "true"
                      ) {
                        clearTimeout(this.noticeTimer);
                        this.noticeTimer = null;
                        this.showTenSecondWarn();
                      } else {
                        clearTimeout(this.noticeTimer);
                        this.noticeTimer = null;
                      }
                    });
                  }
                }
              }
            } else {
              const alarm = res.data.findIndex((e) => e.isStop != 1);
              console.log("alarm", alarm);
              if (alarm != -1) {
                this.alarmNotice = {
                  ...res.data[alarm],
                };
                console.log("this.alarmNotice......", this.alarmNotice);
                if (
                  localStorage.getItem("alarmChecked") == null ||
                  localStorage.getItem("alarmChecked") == "true"
                ) {
                  this.showTenSecondWarn();
                } else {
                  this.alarmNotice = undefined;
                  clearTimeout(this.noticeTimer);
                  this.noticeTimer = null;
                }
              }
            }
            // const alarm = res.data.findIndex((e) => e.isStop != 1);
            // if (alarm != -1) {
            //   this.alarmNotice = {
            //     ...res.data[alarm],
            //   };
            // }
            const noStopData2 = [];
            res.data.forEach((e) => {
              if (e.isStop != 1) {
                noStopData2.push(e);
              }
            });
            if (noStopData2.length) {
              this.warnShow = true;
              // if (!this.noticeTimer) {
              //   this.showTenSecondWarn();
              // }
              if (
                (localStorage.getItem("voiceChecked") == null ||
                  localStorage.getItem("voiceChecked") == "true") &&
                (localStorage.getItem("alarmChecked") == null ||
                  localStorage.getItem("alarmChecked") == "true")
              ) {
                // 播放报警
                this.$refs.audio.play();
              } else {
                // 停止报警语音
                this.$refs.audio.pause();
              }
            } else {
              this.warnShow = false;
              // 停止报警语音
              this.$refs.audio.pause();
              // this.pointList.forEach((point) => {
              //   point.again = false;
              //   point.isAlarm = false;
              // });
              this.alarmNotice = undefined;
              clearTimeout(this.noticeTimer);
              this.noticeTimer = null;
            }
            const ids = [];
            res.data.forEach((e) => {
              ids.push(e.shopId);
            });
            this.pointList.forEach((point) => {
              if (ids.includes(point.shopId)) {
                if (point.isAlarm) {
                  point.again = true;
                }
                point.isAlarm = true;
              } else {
                point.isAlarm = false;
                point.again = false;
              }
            });
          });
        }, 10000);
        // if (
        //   localStorage.getItem("alarmChecked") == null &&
        //   localStorage.getItem("alarmChecked") == "true"
        // ) {
        //   this.showTenSecondWarn();
        // }
      });
    },
    stopCurrentAlarm(fn) {
      const params = {
        alarmPlatformId: this.alarmNotice.alarmPlatformId,
        eventTime: this.alarmNotice.eventTime,
      };
      closeAlarmAll([params]).then((res) => {
        this.$refs.audio.pause();
        if (fn) {
          fn();
        }
      });
    },
    showTenSecondWarn() {
      console.log("this.noticeTimer", this.noticeTimer);
      this.noticeTimer = setInterval(() => {
        this.pointList.forEach((e) => {
          if (!e.again) {
            e.isAlarm = false;
            e.again = false;
          }
        });
        // 新增--播放完设置的报警事件，该条报警不在弹窗提示报警。
        this.stopCurrentAlarm();
        this.alarmNotice = undefined;
        clearTimeout(this.noticeTimer);
        this.noticeTimer = null;
        // if (!this.warnShow) {
        //   this.alarmNotice = undefined;
        //   // 新增--播放完设置的报警事件，该条报警不在弹窗提示报警。
        //   this.stopCurrentAlarm();
        //   clearTimeout(this.noticeTimer);
        //   this.noticeTimer = null;
        // }
      }, (Number(this.alarmNotice.time) + 1) * 1000);
    },
    closeAlarm() {
      this.stopCurrentAlarm(() => {
        this.alarmNotice = undefined;
      });
    },
    getThirdSecondAlarm(type) {
      thirtyAlarmList().then((res) => {
        this.thirdSecondAlarmData = res.data.map((e) => {
          if (e.capturePicture) {
            e.capturePicture = e.capturePicture.split(",");
            e.capturePicture = e.capturePicture.map((ee, i) => {
              ee = this.imgUrl + ee;
              return ee;
            });
          } else {
            e.capturePicture = [];
          }
          return e;
        });
        if (type == 1) {
          this.alarmVisible = true;
        }
      });
    },
    viewRecord(item) {
      let currentTab;
      const accessType = [6, 7, 8];
      if (item.eventTime) {
        currentTab = 2;
      } else if (!item.eventTime && accessType.includes(item.alarmType)) {
        currentTab = 3;
      } else if (!item.eventTime && !accessType.includes(item.alarmType)) {
        currentTab = 1;
      }
      window.open(
        "https://dobayadmin.anasit.com/login?redirect=/shop/device/records&currentTab=" +
          currentTab +
          "&shopName=" +
          item.shopName +
          "&sceneName=" +
          item.channelName +
          "&deviceName=" +
          item.deviceName +
          "&event=" +
          item.event,

        "",
        ""
      );
    },
  },
  created() {
    window.onbeforeunload = function () {
      clearInterval(this.timeer);
      clearInterval(this.shopIndexTimer);
      clearInterval(this.digitalTimer);
      clearInterval(this.wisdomDeviceTimer);
      clearInterval(this.flipperTimer);
      clearInterval(this.warnNoticeTimer);
      clearInterval(this.sopTimer);
      clearInterval(this.deviceDialogTimer);
      clearInterval(this.noticeTimer);
      this.noticeTimer = null;
      this.deviceDialogTimer = null;
      this.sopTimer = null;
      this.timeer = null;
      this.shopIndexTimer = null;
      this.digitalTimer = null;
      this.wisdomDeviceTimer = null;
      this.flipperTimer = null;
      this.warnNoticeTimer = null;
    };
  },
  async mounted() {
    const cards1 = [];
    const cards2 = [];
    cards1[0] = {
      label: "后厨油锅燃气监管",
      title1: "油锅温度",
      value1: 0 + "℃",
      title2: "监管现场",
      value2: "--",
      title3: "燃气浓度",
      value3: false,
      shopName: "",
      operationStatus: "--",
    };
    cards1[1] = {
      label: "留样监管",
      title1: "留样进度",
      value1: "",
      title2: "留样温度",
      value2: 0 + "℃",
      deviceNum: "--",
      isAlarm: false,
      shopName: "",
      operationStatus: "--",
    };
    cards1[2] = {
      label: "主食仓库",
      title1: "挡鼠门",
      value1: "--",
      title2: "防潮光栅",
      value2: "--",
      deviceNum: "--",
      temperature: "--",
      humidity: "--",
      isAlarm: false,
      shopName: "",
      operationStatus: "--",
    };

    cards1[3] = {
      label: "副食仓库",
      title1: "挡鼠门",
      value1: "--",
      isAlarm: false,
      deviceNum: "--",
      temperature: "--",
      humidity: "--",

      shopName: "",
      operationStatus: "--",
    };
    cards2[0] = {
      label: "着装规范",
      title1: "预警",
      value1: 0,
      deviceNum: "--",
      isAlarm: false,
      shopName: "",
      operationStatus: "--",
    };
    cards2[1] = {
      label: "后厨出入监管",
      title1: "晨检",
      value1: "--",
      title2: "陌生人",
      value2: false,
      shopName: "",
      operationStatus: "--",
    };
    cards2[2] = {
      label: "环境消毒",
      title1: "消毒状态",
      value1: "--",
      title2: "消毒时长",
      value2: "--",
      deviceNum: "--",
      isAlarm: false,
      shopName: "",
      operationStatus: "--",
    };
    cards2[3] = {
      label: "食材农残检测",
      title1: "食材结果",
      value1: "--",
      deviceNum: "--",
      isAlarm: false,
      shopName: "",
      operationStatus: "--",
    };
    this.mainSwiperList = [{ cards1: cards1, cards2: cards2, shopId: 0 }];

    const date = this.dayjs().format("YYYY-MM-DD");
    const dayDate = this.dayjs().add(-1, "d").format("YYYY-MM-DD");
    this.date = dayDate;

    let dateData = await conversionDate(date);
    this.nowWeekBegin = dateData.data.beginTime;
    this.nowWeekEnd = dateData.data.endTime;
    this.weekDate = dateData.data;
    if (
      this.dayjs(this.weekData).format("YYYY-MM-DD") >
      this.dayjs().format("YYYY-MM-DD")
    ) {
      dateData = await conversionDate(
        this.dayjs(this.dayjs(this.weekDate.beginTime).add(-1, "d")).format(
          "YYYY-MM-DD"
        )
      );
      this.weekDate = dateData.data;
    }
    let currentDate = new Date(this.weekDate.endTime);
    let dayOfWeek = currentDate.getDay();
    let diff = currentDate.getDate() - dayOfWeek + 1;
    let monday = new Date(currentDate.setDate(diff));
    this.valueWeek = this.dayjs(monday).format("YYYY-MM-DD");
    this.getWeekDay();
    this.getMonthRecord();

    this.getShopList();
    this.widthPix =
      window.screen.width < 1920 ? 1 : Math.floor(window.screen.width / 1920);
    this.getKeySafePieList("pie1", { score: 100 });
    this.getKeySafePieList("pie2", { score: 100 });
    this.getKeySafePieList("pie3", { score: 100 });
    this.getKeySafePieList("pie4", { score: 100 });
    this.getKeySafePieList("pie5", { score: 100 });
    // this.getKeySafePieList("pie6", { score: 100 });
    // this.getKeySafePieList("pie7", { score: 100 });
    // this.getKeySafePieList("pie8", { score: 100 });
    const seriesData = this.dealProgressPieData(
      Number(this.wisdomData.onlineRate),
      Number(this.wisdomData.unlineRate)
    );
    this.$nextTick(() => {
      this.$refs.devicePie.initChart(seriesData, 0, "设备总数");
    });
    this.getShopStatisticsData();
    this.getWisdomDeviceData();
    this.getFoodSafetyIndexData();
    this.getThirdSecondAlarm();
    this.getRealAlarmData();
    this.getWisdomMonit();
    this.getDayData();
    this.getNoticeData();
    if (!this.scrollTimer) {
      this.scrollTimer = setInterval(() => {
        this.getNoticeData();
      }, 180000);
    }
    if (!this.timeer) {
      this.timeer = setInterval(() => {
        this.showFilpper = false;
        this.getDayData();
        this.getWeekDay();
        this.getMonthRecord();
        this.getWisdomDeviceData();
        this.getFoodSafetyIndexData();
        this.getThirdSecondAlarm();
        this.getShopStatisticsData();
        this.getWisdomMonit();
        this.getNoticeData();
      }, 120000);
    }
    this.getDeviceBf();
  },

  activated() {
    if (this.timeer) {
      return;
    }
    this.timeer = setInterval(() => {
      this.showFilpper = false;
      this.getWisdomDeviceData();
      this.getFoodSafetyIndexData();
      this.getThirdSecondAlarm();
      this.getShopStatisticsData();
      this.getWisdomMonit();
      this.getNoticeData();
    }, 120000);
    if (!this.scrollTimer) {
      this.scrollTimer = setInterval(() => {
        this.getNoticeData();
      }, 180000);
    }
    this.getRealAlarmData();
  },

  deactivated() {
    clearInterval(this.timeer);
    clearInterval(this.shopIndexTimer);
    clearInterval(this.digitalTimer);
    clearInterval(this.wisdomDeviceTimer);
    clearInterval(this.flipperTimer);
    clearInterval(this.warnNoticeTimer);
    clearInterval(this.sopTimer);
    clearInterval(this.deviceDialogTimer);
    clearInterval(this.noticeTimer);
    clearTimeout(this.scrollTimer);
    this.scrollTimer = null;
    this.noticeTimer = null;
    this.deviceDialogTimer = null;
    this.sopTimer = null;
    this.timeer = null;
    this.shopIndexTimer = null;
    this.digitalTimer = null;
    this.wisdomDeviceTimer = null;
    this.flipperTimer = null;
    this.warnNoticeTimer = null;
    this.alarmNotice = undefined;
    clearTimeout(this.noticeTimer);
    this.noticeTimer = null;
    this.$refs.audio.pause();
  },

  destroyed() {
    this.alarmNotice = undefined;
    clearTimeout(this.noticeTimer);
    this.noticeTimer = null;
    clearInterval(this.timeer);
    clearInterval(this.shopIndexTimer);
    clearInterval(this.digitalTimer);
    clearInterval(this.wisdomDeviceTimer);
    clearInterval(this.flipperTimer);
    clearInterval(this.warnNoticeTimer);
    clearInterval(this.sopTimer);
    clearInterval(this.deviceDialogTimer);
    clearInterval(this.noticeTimer);
    clearTimeout(this.scrollTimer);
    clearTimeout(this.scrollScrollTimer);
    this.scrollTimer = null;
    this.scrollScrollTimer = null;
    this.noticeTimer = null;
    this.deviceDialogTimer = null;
    this.sopTimer = null;
    this.timeer = null;
    this.shopIndexTimer = null;
    this.digitalTimer = null;
    this.wisdomDeviceTimer = null;
    this.flipperTimer = null;
    this.warnNoticeTimer = null;
    this.$refs.audio.pause();
  },
};
