<template>
  <div class="marquee-container" @mouseenter="pauseMarquee" @mouseleave="resumeMarquee">
    <!-- || isStop -->
    <div v-if="cardNum>4" id="srollBox" class="marquee2" :class="{ paused: isPaused }" @mousedown="startDrag" @mouseup="stopDrag" :style="{ transform: 'translateX(' + translateX + 'px)' }">
      <slot></slot>
    </div>
    <div v-else id="srollBox" :style="{ transform: 'translateX(' + translateX + 'px)' }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    isDragging() {
      if (this.isDragging == true) {
        document.addEventListener("mouseup", () => {
          this.stopDrag();
        });
      }
    },
    shopId() {
        this.getBegin();
    },
    cardNum(){
        this.distance =  (this.cardNum - 3)*25;
        this.startMarquee();

    },
  },
  props: {
    shopId: {
      default: 0,
    },
    cardNum:{
        default: 0,
        type: Number
    },
    // num: {
    //   default: 0,
    // },
  },
  data() {
    return {
      distance:128,
      isPaused: false,
      isShow: true,
      num: 0,
      isTenLate: false,
      isDragging: false,
      startX: 0,
      currentX: 0,
      translateX: 0,
      timer: "",
      timer2: "",
      timerOut1: "",
      timerOut2: "",

      num2: 0,
    };
  },
  mounted() {
    // 开始跑马灯效果
    // this.startMarquee();
  },
  destroyed() {
    if (this.timerOut1) {
      clearTimeout(this.timerOut1);
    }
    if (this.timerOut2) {
      clearTimeout(this.timerOut2);
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.timer2) {
      clearInterval(this.timer2);
    }
    
  },
  methods: {
    getTimeNow() {
      let now = new Date();
      return now.getTime();
    },

    startDrag(event) {
      document.onselectstart = () => {
        return false;
      }; //解决拖动会选中文字的问题
      document.ondragstart = () => {
        return false;
      };
      let doc = document.getElementById("srollBox");
      clearInterval(this.timer);
      this.startX = event.clientX;
      this.currentX = event.clientX;
      let timeBegin = this.getTimeNow();
      this.timer2 = setInterval(() => {
        let timeEnd = this.getTimeNow();
        this.isDragging = true;
        if (timeEnd - timeBegin > 500) {
          //便不再继续重复此函数 （clearInterval取消周期性执行）
          clearInterval(this.timer2);

          doc.addEventListener("mousemove", this.handleDrag);
        }
      }, 100);
    },
    handleDrag(event) {
      if (this.isDragging) {
        const deltaX = event.clientX - this.currentX;
        this.currentX = event.clientX;
        this.translateX += deltaX;
      }
    },
    stopDrag(event) {
      document.onselectstart = () => {
        return null;
      }; //解决拖动会选中文字的问题
      document.ondragstart = () => {
        return null;
      };
      let doc = document.getElementById("srollBox");
      doc.removeEventListener("mousemove", this.handleDrag);
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.isDragging) {
        this.num = ((this.translateX / doc.offsetWidth) * 100).toFixed(0);
      }
      if (this.num < -this.distance) {
        this.num = -this.distance;
        this.translateX = -(this.distance/100) * doc.offsetWidth;
      }
      if (this.num > 0) {
        this.num = 0;
        this.translateX = 0;
      }
      // console.log(this.num);
      clearInterval(this.timer2);

      let isBottom = false;
      this.timer = setInterval(() => {
        if (this.num == 0) {
          isBottom = false;
        }
        if (this.num == -this.distance) {
          this.num = 0;
          // isBottom = true;
          // clearInterval(this.timer);
          // if (this.timerOut1) {
          //   clearTimeout(this.timerOut1);
          // }
          // this.timerOut1 = setTimeout(() => {
          //   this.$emit("getIsPause", this.num);
          // }, 10000);
        }
        if (!this.isPaused) {
          if (!isBottom) {
            this.num = this.num - 1;
            doc.style.transform = "translateX(" + this.num + "%)";
          }
        }
      }, 250);
      this.isDragging = false;
    },
    getBegin() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      clearInterval(this.timer2);

      const doc = document.getElementById("srollBox");

      doc.style.transform = "translateX(0)";
      this.num = 0;

      let isBottom = false;
      if (this.timerOut2) {
        clearTimeout(this.timerOut2);
      }
        this.timerOut2 = setTimeout(() => {
            console.log(this.cardNum>4);
            
            if(this.cardNum>4){
                this.timer = setInterval(() => {
                if (this.num == 0) {
                    isBottom = false;
                }
                if (this.num == -this.distance) {
                    this.num = 0;
                }
                if (!this.isPaused) {
                    if (!isBottom) {
                    this.num = this.num - 1;
                    doc.style.transform = "translateX(" + this.num + "%)";
                    }
                }
                }, 250);
            }
            
        }, 2000);
      
    },
    startMarquee() {
      let doc = document.getElementById("srollBox");
      // 获取.marquee元素的高度
      
      if (this.timerOut1) {
        clearTimeout(this.timerOut1);
      }
      if (this.timerOut2) {
        clearTimeout(this.timerOut2);
      }
      if (this.timer) {
        clearInterval(this.timer);
      }
      let isBottom = false;
      this.timerOut2 = setTimeout(() => {
        console.log(this.cardNum);
        
        if(this.cardNum>4){
            this.timer = setInterval(() => {
                if (this.num == 0) {
                    isBottom = false;
                }
                if (this.num == -this.distance) {
                    this.num = 0;
                }
                if (!this.isPaused) {
                    if (!isBottom) {
                    this.num = this.num - 1;
                    doc.style.transform = "translateX(" + this.num + "%)";
                    }
                }
            }, 250);
        }
      }, 2000);
    },
    pauseMarquee() {
      // 设置动画暂停
      this.isPaused = true;
      this.$emit("getIsPause3", this.isPaused);
    },
    resumeMarquee() {
      // 设置动画继续
      this.isPaused = false;
      this.$emit("getIsPause3", this.isPaused);
    },
  },
};
</script>

<style scoped>
.marquee-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.marquee2 {
  display: block;
  transform: translateX(0);
  transition: all 0.25s linear;

  /* animation: marquee-animation linear;
  animation-delay: 2s; */
}

.paused {
  animation-play-state: paused;
}

/* @keyframes marquee-animation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-128%);
  }
  100% {
    transform: translateX(-128%);
  }
} */
</style>
